import { ReservationIntentOriginEnum } from '@/graphql/types.generated';

export type WizardBooking = {
  pax?: number;
  date?: string;
  time?: string;
  hour?: string; // using by the success step, we receive a datetime without timeslot.
  offer?: string;
  offerName?: string;
  offerPrice?: number;
  offerCurrency?: string;
  customFieldValues?: Record<string, string | number | null>;
  customFieldLabels?: Record<string, string>;
  customFieldProperties?: Record<string, { title: string }>;
  step?: string;
  crossSellUuid?: string;
  customerUuid?: string;
  reservationUuid?: string;
  theForkNewsletterOptIn?: boolean;
  restaurantNewsletterOptIn?: boolean;
  areaUuid?: string;
  customFields?: string;
  isWaitingList?: boolean;
  specialRequest?: string;
  mealDuration?: number;
  couponCode?: string | null;
  reservationIntentUuid?: string;
  origin?: ReservationIntentOriginEnum;
};

export enum WizardStep {
  Blank = 'blank',
  Pax = 'pax',
  Date = 'date',
  Hour = 'hour',
  Result = 'result',
  Offer = 'offer',
  UserInformation = 'info',
  MoreInfo = 'moreInfo',
  Payment = 'pay',
  Success = 'success',
  Error = 'error',
  CouponRecap = 'couponRecap',
  Playground = 'playground',
}
