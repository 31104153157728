import { ReactNode } from 'react';
import * as S from './Message.styles';

export type MessageVariant = 'information' | 'warning' | 'outline' | 'alert';

export type MessageProps = {
  variant: MessageVariant;
  children: ReactNode;
  className?: string;
  Icon?: React.FC<{ className?: string; fontSize?: string }>;
  dataTestId?: string;
};

export const Message = ({ variant, children, className, Icon, dataTestId }: MessageProps) => {
  return (
    <S.Container className={className} variant={variant} data-testid={dataTestId}>
      <S.MessageIcon variant={variant} Icon={Icon} />
      <S.FlexC flexDirection="column" alignItems="flex-start">
        {children}
      </S.FlexC>
    </S.Container>
  );
};
