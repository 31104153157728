import React, { useCallback, useMemo, useState } from 'react';
import { ErrorBoundary } from '@/components/ErrorBoundary/ErrorBoundary';
import { WizardSubtitle } from '@/components/WizardLayout/Subtitle/WizardSubtitle';
import { WizardStep } from '@/types/WizardBooking';
import { WizardStepper } from '@/components/WizardLayout/Stepper/WizardStepper';
import { TFBranding } from '@/components/TFBranding/TFBranding';
import { useIntl } from 'react-intl';
import { LanguageSelector } from '@/components/LanguageSelector/LanguageSelector';
import { useRouter } from 'next/router';
import { serializeCookie } from '@/utils/cookie-utils/cookie-utils';
import { Cookie } from '@/types/cookie';
import { BackButton } from '@/components/WizardLayout/BackButton/BackButton';
import { useBooking } from '@/hooks/useBooking';
import { Mention } from '@/components/Mention/Mention';
import { formatCloudinaryImageUrl } from '@/utils/format-cloudinary-image-utils';
import { WidgetHomepageFragment } from '@/graphql/types.generated';
import { useRestaurantDisplay } from '@/hooks/useRestaurantDisplay';
import { usePaymentContext } from '@/contexts/PaymentContext/PaymentContext';
import { UserCheckoutStepper } from '@/components/WizardLayout/UserCheckoutStepper/UserCheckoutStepper';

import * as S from './WizardLayout.styles';
import { LeavePaymentStepModal } from '@/components/WizardLayout/LeavePaymentStepModal/LeavePaymentStepModal';

export type WizardLayoutProps = {
  title: string;
  isCouponEnabled: boolean;
  homepage?: WidgetHomepageFragment;
  children?: React.ReactNode;
};

export const WizardLayout: React.FC<WizardLayoutProps> = ({ title, children, homepage, isCouponEnabled }) => {
  const intl = useIntl();
  const { asPath } = useRouter();
  const { handleStepperUndo, handleErrorGoBack, step } = useBooking();
  const paymentData = usePaymentContext();
  const {
    restaurantBannerUrl,
    restaurantLogoUrl,
    shouldDisplayLanguageSelector,
    shouldDisplayRestaurantBanner,
    shouldDisplayRestaurantLogo,
    shouldDisplayRestaurantName,
    shouldDisplayTheForkLogo,
  } = useRestaurantDisplay({ homepage });
  const [isLeavePaymentStepModalOpen, setIsLeavePaymentStepModalOpen] = useState(false);

  const handleLanguageSelection = useCallback(
    (language: string) => {
      document.cookie = serializeCookie(Cookie.LANGUAGE, language);
      location.href = `/${language}${asPath}`;
    },
    [asPath],
  );

  const handleBackButton = useCallback(() => {
    if (step === WizardStep.Payment) {
      setIsLeavePaymentStepModalOpen(true);
    } else {
      handleStepperUndo();
    }
  }, [handleStepperUndo, step]);

  const isStepDHP = [WizardStep.Date, WizardStep.Hour, WizardStep.Pax].includes(step);
  const isStepNoBackButton = isStepDHP || [WizardStep.Blank, WizardStep.Error, WizardStep.CouponRecap].includes(step);
  const isStepInfo = [WizardStep.UserInformation, WizardStep.MoreInfo].includes(step);
  const showMention = !homepage && step !== WizardStep.MoreInfo;

  const wizardSteps = useMemo(
    () => [
      WizardStep.UserInformation,
      WizardStep.MoreInfo,
      ...(paymentData.needsPayment ? [WizardStep.Payment] : []),
      WizardStep.Success,
    ],
    [paymentData.needsPayment],
  );

  const currentStep = useMemo(() => {
    const wizardStep = wizardSteps.findIndex((wizStep) => step === wizStep);
    return wizardStep + 1;
  }, [step, wizardSteps]);

  const showBanner = useMemo(() => {
    return [
      shouldDisplayRestaurantBanner,
      shouldDisplayRestaurantLogo,
      shouldDisplayRestaurantName,
      shouldDisplayTheForkLogo,
    ].some(Boolean);
  }, [
    shouldDisplayRestaurantBanner,
    shouldDisplayRestaurantLogo,
    shouldDisplayRestaurantName,
    shouldDisplayTheForkLogo,
  ]);

  const showUserCheckoutStepper = wizardSteps.includes(step);

  return (
    <S.Container>
      {isLeavePaymentStepModalOpen && (
        <LeavePaymentStepModal
          handleModalClose={() => setIsLeavePaymentStepModalOpen(false)}
          handleModalCloseAndGoBack={() => {
            setIsLeavePaymentStepModalOpen(false);
            handleStepperUndo();
          }}
        />
      )}
      <S.FixedHeader>
        <S.GridHeader isDHP={isStepDHP} showBanner={showBanner}>
          {!isStepNoBackButton && <BackButton handleStepperUndo={handleBackButton} />}

          {shouldDisplayRestaurantLogo && (
            <S.HeaderLogo
              isDHP={isStepDHP}
              src={formatCloudinaryImageUrl({ url: restaurantLogoUrl || '', width: '80' })}
              alt={intl.formatMessage({
                id: 'tf_widget_restaurant_logo',
                defaultMessage: 'Restaurant logo',
              })}
              data-testid="restaurant-logo"
            />
          )}

          <S.HeaderTitleWrapper hasBanner={shouldDisplayRestaurantBanner}>
            {shouldDisplayRestaurantName && (
              <S.HeaderTitle
                isDHP={isStepDHP}
                hasBackground={shouldDisplayRestaurantBanner}
                variant="title3"
                as="h1"
                data-testid="restaurant-name"
              >
                {title}
              </S.HeaderTitle>
            )}
            {shouldDisplayTheForkLogo && <TFBranding isDHP={isStepDHP} hasBackground={shouldDisplayRestaurantBanner} />}
          </S.HeaderTitleWrapper>

          {shouldDisplayRestaurantBanner && (
            <S.RestaurantVisual
              isDHP={isStepDHP}
              bannerUrl={formatCloudinaryImageUrl({
                url: restaurantBannerUrl || '',
                width: 'iw',
                height: '100',
                gravity: 'auto',
              })}
              data-testid="restaurant-banner"
            />
          )}

          {shouldDisplayLanguageSelector && (
            <S.LanguageSelectorContainer isDHP={isStepDHP}>
              <LanguageSelector handleLanguageSelection={handleLanguageSelection} data-testid="language-selector" />
            </S.LanguageSelectorContainer>
          )}
          {showUserCheckoutStepper && <WizardSubtitle />}

          {!homepage && (
            <S.WizardStepperContainer>
              {showUserCheckoutStepper && <UserCheckoutStepper currentStep={currentStep} steps={wizardSteps.length} />}
              <WizardStepper isCouponEnabled={isCouponEnabled} />
            </S.WizardStepperContainer>
          )}
        </S.GridHeader>

        {!showUserCheckoutStepper && <WizardSubtitle />}
      </S.FixedHeader>
      {showMention && (
        <S.MentionWrapper>
          <Mention />
        </S.MentionWrapper>
      )}
      <ErrorBoundary
        module="PageContent"
        handleGoBack={step !== WizardStep.Pax ? handleErrorGoBack : undefined}
        key={step}
      >
        <S.MainContentBox step={step}>{children}</S.MainContentBox>
      </ErrorBoundary>
    </S.Container>
  );
};
