import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CountryCode, CountryCallingCode } from 'libphonenumber-js/max';
import { getCountriesOptions } from './countries';
import * as S from './PhoneCountryCodeSelect.styles';
import { Option, OptionValue } from './PhoneCountryCodeSelect.types';
import { createFilter } from 'react-select';

type OptionType = {
  countryCode: CountryCode;
  prefix: CountryCallingCode;
};

export type PhoneCountryCodeSelectProps = {
  country: string;
  disabled?: boolean;
  onChange?: (value: OptionType | null) => void;
  onBlur?: () => void;
  'data-testid'?: string;
};

const filterOptionText = createFilter<{ label: string; value: string }>({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any',
  trim: true,
});

export const filterOption = ({ data }: { data: Option }, inputValue: string) => {
  if (data.value.countryCallingCode.includes(inputValue.replace(/\+/g, ''))) {
    return true;
  }
  const dataForSearch = { label: data.label, value: data.value.countryCode };
  return filterOptionText({ ...dataForSearch, data: dataForSearch }, inputValue);
};

export const PhoneCountryCodeSelect = ({
  country,
  disabled = false,
  onChange,
  onBlur,
  'data-testid': dataTestId,
  ...props
}: PhoneCountryCodeSelectProps) => {
  const intl = useIntl();
  const handleChange = (value: OptionValue | null) => {
    onChange &&
      onChange(
        value
          ? {
              countryCode: value.countryCode,
              prefix: value.countryCallingCode,
            }
          : null,
      );
  };
  const countriesOptions = useMemo(() => getCountriesOptions(intl), [intl]);
  const selectedOption = countriesOptions.find(({ value }) => value.countryCode === country);
  return (
    <S.StyledSelect
      isDisabled={disabled}
      id={dataTestId}
      classNamePrefix="phoneSelect"
      options={countriesOptions}
      name="phone-prefix"
      isClearable={false}
      isSearchable
      onChange={handleChange}
      onBlur={onBlur}
      value={selectedOption?.value}
      canHaveLegend={false}
      filterOption={filterOption}
      data-testid={dataTestId}
      formatOptionLabel={({ label, value }, { context }: { context: string }) => (
        <S.OptionWrapper>
          <S.CountryFlag className={`fi fi-${value.countryCode.toLocaleLowerCase()}`} />
          {context === 'menu' ? <S.CountryName>{label}</S.CountryName> : null}
          <S.CountryPrefix>+{value.countryCallingCode}</S.CountryPrefix>
        </S.OptionWrapper>
      )}
      {...props}
    />
  );
};
