import React from 'react';
import * as S from './Step.styles';

export type StepStatus = 'active' | 'completed' | 'disabled' | 'confirmed';

export type StepProps = {
  status: StepStatus;
  number?: number;
};

export const Step: React.FC<StepProps> = ({ status, number }) => {
  switch (status) {
    case 'active':
    case 'disabled':
      return <S.Step disabled={status === 'disabled'}>{number}</S.Step>;
    case 'completed':
      return <S.StepCompleted />;
    case 'confirmed':
      return <S.StepConfirmation />;
  }
};
