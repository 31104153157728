import { TranslationItemKeys } from '@/types/config';
import { WidgetSettingFragment } from '@/graphql/types.generated';

export const getTranslationValue = (
  widgetSetting: WidgetSettingFragment | undefined | null,
  key: TranslationItemKeys,
  defaultValue?: string,
) => {
  const defaultOut = defaultValue || '';
  if (widgetSetting?.locale) {
    const translationItem = widgetSetting.locale.translations.find((item) => item.key === key);
    return translationItem?.value || defaultOut;
  }
  return defaultOut;
};
