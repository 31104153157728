import styled from '@emotion/styled';
import { Select as CustomSelect } from '@/components/Select/Select';
import { BELOW_MOBILE_LANDSCAPE } from '@/utils/emotion-utils';

const SELECT_WIDTH = '5rem';

export const Select = styled(CustomSelect<string>)(
  ({ theme }) => `
  width: ${SELECT_WIDTH};

  ${BELOW_MOBILE_LANDSCAPE} {
    .language-selector__control {
      border-bottom: 2px solid ${theme.customization.colors.fields.border} !important;
    }
    &,
    .language-selector__single-value {
      color: ${theme.customization.colors.text.onFields};
    }
    & .language-selector__menu-list {
      color: ${theme.customization.colors.text.onFields};
    }
  }

  .language-selector__control--menu-is-open {
    border-bottom: 2px solid ${theme.customization.colors.fields.pressed} !important;
  }  
`,
);
