import React, { useState } from 'react';
import { Checkbox } from '@/components/ReactHookForm/Checkbox';
import { Input } from '@/components/ReactHookForm/Input';
import { PhoneSelect } from '@/components/ReactHookForm/PhoneSelect';
import { Select as RawSelect } from '@/components/Select/Select';
import { usePaymentContext } from '@/contexts/PaymentContext/PaymentContext';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { useBooking } from '@/hooks/useBooking';
import { Loader } from '@/components/Loader/Loader';
import { CivilityEnum } from 'graphql/types.generated';
import { FormattedMessage, useIntl } from 'react-intl';
import { PaymentNotice, PaymentType } from '@/components/PaymentNotice/PaymentNotice';
import { default_country } from '@/utils/constants';
import { getTranslationValue } from '@/utils/translation-utils';
import { WizardStep } from '@/types/WizardBooking';
import { TranslationItemKeys } from '@/types/config';
import { civilityMapping } from '@/types/civility';
import { Button } from '@/components/Button/Button';
import { SingleRadio } from '@/components/ReactHookForm/Radio';
import { Message } from '@/components/Message/Message';
import { VoucherNotice } from '@/components/VoucherNotice/VoucherNotice';
import { IconExclamationmarkCircleFill } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { SeatingTimeMessage } from '@/components/SeatingTimeMessage/SeatingTimeMessage';
import { useContactForm } from '@/components/ContactForm/hook/useContactForm';
import { ButtonContainer } from '@/components/ButtonContainer/ButtonContainer';
import * as S from './ContactForm.styles';
import { RequiredLegend } from '@/components/RequiredLegend/RequiredLegend';

type CivilitySelector = CivilityEnum.Mr | CivilityEnum.Ms | CivilityEnum.Mx | 'custom';
export const A_LA_CARTE_OFFER = 'none';

export type ContactInformation = {
  civility?: CivilityEnum;
  customCivility?: CivilityEnum;
  civilitySelector?: CivilitySelector;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  restaurantNewsletterOptIn: boolean;
  theForkNewsletterOptIn: boolean;
  isWaitingList?: boolean;
};

export const ContactForm: React.FC = () => {
  const { handleSelectStep } = useBooking();
  const paymentData = usePaymentContext();

  const intl = useIntl();

  const { widgetConfig, isTfBrandingDisplayed } = useWidgetConfig();

  if (!widgetConfig?.restaurant) {
    throw new Error('Required widgetConfig not provided');
  }

  const restaurantTermsConditions = getTranslationValue(
    widgetConfig?.settings,
    TranslationItemKeys.TRANSLATION_TERMS_CONDITIONS,
  );
  const restaurantDataCollectionNotice = getTranslationValue(
    widgetConfig?.settings,
    TranslationItemKeys.TRANSLATION_DATA_COLLECTION,
  );
  const [isTCModalOpen, setIsTCModalOpen] = useState(false);
  const [isDCNModalOpen, setIsDCNModalOpen] = useState(false);

  const {
    pax,
    isWaitingList,
    mealStart,
    control,
    formState,
    setValue,
    civilitySelector,
    customCivility,
    isSubmitting,
    mealDuration,
    submitContactForm,
    handleSubmit,
    voucherData,
    isLoading,
  } = useContactForm({
    onSubmitSuccess: (contactInformation) => {
      handleSelectStep({
        customerUuid: contactInformation.customerUuid,
        theForkNewsletterOptIn: contactInformation.theForkNewsletterOptIn,
        restaurantNewsletterOptIn: contactInformation.restaurantNewsletterOptIn,
        step: WizardStep.MoreInfo,
        mealDuration,
      });
    },
  });

  const submitButtonLegend = intl.formatMessage({
    id: 'tf_widget_contact_cta_next',
    defaultMessage: 'Next',
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <S.Form noValidate onSubmit={handleSubmit(submitContactForm)}>
      {(paymentData.needsPayment || voucherData || isWaitingList) && (
        <S.NoticeVoucherContainer>
          {isWaitingList && (
            <Message
              variant="information"
              Icon={IconExclamationmarkCircleFill}
              dataTestId="waiting_list_this_is_not_a_confirmed_reservation"
            >
              <FormattedMessage
                id="tf_widget_waiting_list_this_is_not_a_confirmed_reservation"
                defaultMessage="This is not a confirmed reservation, but rather a placement on the waiting list. The restaurant's availability is not guaranteed; however, you will be notified once a table becomes available."
              />
            </Message>
          )}
          {paymentData.config && (
            <PaymentNotice
              paymentType={paymentData.config.imprint ? PaymentType.IMPRINT : PaymentType.PREPAYMENT}
              amount={paymentData.config.amount}
              currency={paymentData.config.currency}
              isAutoRefundable={paymentData.config.paymentProvider.isAutoRefundEnabled}
            />
          )}
          {voucherData && <VoucherNotice reservationPax={pax} voucher={voucherData} />}
        </S.NoticeVoucherContainer>
      )}
      <S.FormContainer>
        <S.CivilityContainer>
          <S.CivilityLeftCol>
            <S.StyledCivilityRadios
              control={control}
              name="civilitySelector"
              prefix="contact-information"
              values={[
                [
                  CivilityEnum.Mr,
                  intl.formatMessage({
                    id: 'tf_widget_contact_mr',
                    defaultMessage: 'Mr',
                  }),
                ],
                [
                  CivilityEnum.Mrs,
                  intl.formatMessage({
                    id: 'tf_widget_contact_mrs',
                    defaultMessage: 'Mrs',
                  }),
                ],
                [
                  CivilityEnum.Mx,
                  intl.formatMessage({
                    id: 'tf_widget_contact_mx',
                    defaultMessage: 'Mx',
                  }),
                ],
              ]}
            />
          </S.CivilityLeftCol>
          <S.CivilityRightCol>
            <SingleRadio
              id="customCivilitySelector"
              name="civilitySelector"
              onChange={(v) => setValue('civilitySelector', v.currentTarget.value as CivilitySelector)}
              value="custom"
              aria-labelledby={intl.formatMessage({
                id: 'tf_widget_contact_honorific_other',
                defaultMessage: 'Other — Please specify',
              })}
            />
            <RawSelect<string>
              isClearable={false}
              isSearchable
              canHaveLegend={false}
              name="customCivility"
              isDisabled={civilitySelector !== 'custom'}
              options={Object.entries(civilityMapping).map(([value, translation]) => ({
                value,
                label: intl.formatMessage(translation),
              }))}
              placeholder={intl.formatMessage({
                id: 'tf_widget_contact_honorific_other',
                defaultMessage: 'Other — Please specify',
              })}
              onChange={(val) => setValue('customCivility', val as CivilityEnum)}
              value={customCivility}
              isFullWidth
            />
          </S.CivilityRightCol>
        </S.CivilityContainer>
        <S.InputContainer>
          {/* @ts-expect-error children and id are not expected */}
          <Input
            control={control}
            name="firstName"
            label={intl.formatMessage({
              id: 'tf_widget_contact_firstname',
              defaultMessage: 'First name',
            })}
            placeholder={intl.formatMessage({
              id: 'tf_widget_contact_enter_first_name',
              defaultMessage: 'Enter your first name',
            })}
            type="text"
            prefix="contact-information"
            required
          />
          {/* @ts-expect-error children and id are not expected */}
          <Input
            type="text"
            control={control}
            name="lastName"
            prefix="contact-information"
            required
            label={intl.formatMessage({
              id: 'tf_widget_contact_lastname',
              defaultMessage: 'Last name',
            })}
            placeholder={intl.formatMessage({
              id: 'tf_widget_contact_enter_last_name',
              defaultMessage: 'Enter your last name',
            })}
          />
          {/* @ts-expect-error children and id are not expected */}
          <Input
            control={control}
            type="text"
            name="email"
            prefix="contact-information"
            label={intl.formatMessage({
              id: 'tf_widget_contact_email',
              defaultMessage: 'Email',
            })}
            placeholder={intl.formatMessage({
              id: 'tf_widget_contact_email_example',
              defaultMessage: 'example@domain.com',
            })}
            required
          />
          <PhoneSelect
            control={control}
            name="phone"
            countryCode={widgetConfig.restaurant.address?.country ?? default_country}
            label={intl.formatMessage({
              id: 'tf_widget_contact_phone',
              defaultMessage: 'Phone',
            })}
            prefix="contact-information"
            required
          />
        </S.InputContainer>
        <RequiredLegend />

        {mealDuration != null && (
          <S.MealDurationContainer>
            <SeatingTimeMessage mealDuration={mealDuration} mealStart={mealStart} />
          </S.MealDurationContainer>
        )}
        <S.PoliciesContainer>
          {isTfBrandingDisplayed && (
            <S.CheckboxContainer>
              <Checkbox
                control={control}
                name="theForkNewsletterOptIn"
                label={intl.formatMessage({
                  id: 'tf_widget_TF_contact_newsletter_v3',
                  defaultMessage: 'Sign me up for special offers and news from the TheFork via email',
                })}
                prefix="contact-information"
              />
            </S.CheckboxContainer>
          )}
          <S.CheckboxContainer>
            <Checkbox
              control={control}
              name="restaurantNewsletterOptIn"
              label={intl.formatMessage({
                id: 'tf_widget_contact_newsletter_v3',
                defaultMessage: 'Sign me up for special offers and news from this restaurant via email and text',
              })}
              prefix="contact-information"
            />
          </S.CheckboxContainer>
        </S.PoliciesContainer>
        <S.Terms>
          {isTCModalOpen && (
            <S.LegalModal
              title={intl.formatMessage({
                id: 'tf_widget_booking_termsTitle',
                defaultMessage: 'Terms of use',
              })}
              onClose={() => setIsTCModalOpen(false)}
            >
              {restaurantTermsConditions}
            </S.LegalModal>
          )}
          {isDCNModalOpen && (
            <S.LegalModal
              title={intl.formatMessage({
                id: 'tf_widget_booking_dataCollectionTitle',
                defaultMessage: 'Privacy policy',
              })}
              onClose={() => setIsDCNModalOpen(false)}
            >
              {restaurantDataCollectionNotice}
            </S.LegalModal>
          )}

          {!restaurantTermsConditions && !restaurantDataCollectionNotice && (
            <>
              <FormattedMessage
                id="tf_widget_booking_default_legal_theFork"
                defaultMessage="By clicking « {submitButtonLegend} », you agree to TheFork's <termsLink>Terms of Use</termsLink> and <privacyLink>Privacy Policy</privacyLink>."
                values={{
                  termsLink: (chunk) => (
                    <a
                      href={intl.formatMessage({
                        id: 'tf_widget_terms_link',
                        defaultMessage: 'https://www.thefork.com/legal#TermsOfUse',
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunk}
                    </a>
                  ),
                  privacyLink: (chunk) => (
                    <a
                      href={intl.formatMessage({
                        id: 'tf_widget_privacy_link',
                        defaultMessage: 'https://www.thefork.com/legal#PrivacyPolicy',
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunk}
                    </a>
                  ),
                  submitButtonLegend: submitButtonLegend,
                }}
              />
            </>
          )}

          {restaurantTermsConditions && restaurantDataCollectionNotice && (
            <>
              <FormattedMessage
                id="tf_widget_booking_default_legal_theFork_restaurant_terms_condition_privacy_policy"
                defaultMessage="By clicking « {submitButtonLegend} », you agree to TheFork's <termsLink>Terms of Use</termsLink> and <privacyLink>Privacy Policy</privacyLink>, and {restaurantName}'s <restaurantTermsLink>Terms & Conditions</restaurantTermsLink> and <restaurantPrivacyLink>Privacy Policy</restaurantPrivacyLink>."
                values={{
                  termsLink: (chunk) => (
                    <a
                      href={intl.formatMessage({
                        id: 'tf_widget_terms_link',
                        defaultMessage: 'https://www.thefork.com/legal#TermsOfUse',
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunk}
                    </a>
                  ),
                  privacyLink: (chunk) => (
                    <a
                      href={intl.formatMessage({
                        id: 'tf_widget_privacy_link',
                        defaultMessage: 'https://www.thefork.com/legal#PrivacyPolicy',
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunk}
                    </a>
                  ),
                  submitButtonLegend: submitButtonLegend,
                  restaurantName: widgetConfig.restaurant.name,
                  restaurantTermsLink: (chunk) => (
                    <S.LinkStyle onClick={() => setIsTCModalOpen(true)}>{chunk}</S.LinkStyle>
                  ),
                  restaurantPrivacyLink: (chunk) => (
                    <S.LinkStyle onClick={() => setIsDCNModalOpen(true)}>{chunk}</S.LinkStyle>
                  ),
                }}
              />
            </>
          )}

          {restaurantTermsConditions && !restaurantDataCollectionNotice && (
            <>
              <FormattedMessage
                id="tf_widget_booking_default_legal_theFork_restaurant_terms_condition"
                defaultMessage="By clicking « {submitButtonLegend} », you agree to TheFork's <termsLink>Terms of Use</termsLink> and <privacyLink>Privacy Policy</privacyLink>, and {restaurantName}'s <restaurantTermsLink>Terms & Conditions</restaurantTermsLink>."
                values={{
                  termsLink: (chunk) => (
                    <a
                      href={intl.formatMessage({
                        id: 'tf_widget_terms_link',
                        defaultMessage: 'https://www.thefork.com/legal#TermsOfUse',
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunk}
                    </a>
                  ),
                  privacyLink: (chunk) => (
                    <a
                      href={intl.formatMessage({
                        id: 'tf_widget_privacy_link',
                        defaultMessage: 'https://www.thefork.com/legal#PrivacyPolicy',
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunk}
                    </a>
                  ),
                  submitButtonLegend: submitButtonLegend,
                  restaurantName: widgetConfig.restaurant.name,
                  restaurantTermsLink: (chunk) => (
                    <S.LinkStyle onClick={() => setIsTCModalOpen(true)}>{chunk}</S.LinkStyle>
                  ),
                }}
              />
            </>
          )}

          {!restaurantTermsConditions && restaurantDataCollectionNotice && (
            <>
              <FormattedMessage
                id="tf_widget_booking_default_legal_theFork_restaurant_privacy_policy"
                defaultMessage="By clicking « {submitButtonLegend} », you agree to TheFork's <termsLink>Terms of Use</termsLink> and <privacyLink>Privacy Policy</privacyLink>, and {restaurantName}'s <restaurantPrivacyLink>Privacy Policy</restaurantPrivacyLink>."
                values={{
                  termsLink: (chunk) => (
                    <a
                      href={intl.formatMessage({
                        id: 'tf_widget_terms_link',
                        defaultMessage: 'https://www.thefork.com/legal#TermsOfUse',
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunk}
                    </a>
                  ),
                  privacyLink: (chunk) => (
                    <a
                      href={intl.formatMessage({
                        id: 'tf_widget_privacy_link',
                        defaultMessage: 'https://www.thefork.com/legal#PrivacyPolicy',
                      })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunk}
                    </a>
                  ),
                  submitButtonLegend: submitButtonLegend,
                  restaurantName: widgetConfig.restaurant.name,
                  restaurantPrivacyLink: (chunk) => (
                    <S.LinkStyle onClick={() => setIsDCNModalOpen(true)}>{chunk}</S.LinkStyle>
                  ),
                }}
              />
            </>
          )}
        </S.Terms>
      </S.FormContainer>
      <ButtonContainer>
        <Button
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || !formState.isValid}
          data-testid="contact-form-next-button"
          showArrow
        >
          {submitButtonLegend}
        </Button>
      </ButtonContainer>
    </S.Form>
  );
};
