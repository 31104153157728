import * as S from './BackButton.styles';
import { FormattedMessage } from 'react-intl';
import { IconArrowLeft } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { WizardStep } from '@/types/WizardBooking';

export type BackButtonProps = {
  handleStepperUndo: (step?: WizardStep) => void;
};

export const BackButton = ({ handleStepperUndo }: BackButtonProps) => {
  return (
    <S.BackButtonContainer>
      <S.BackButton variant="ghost" isSmall onClick={() => handleStepperUndo()} data-testid="back-button">
        <IconArrowLeft />
        <FormattedMessage id="tf_widget_wizard_back" defaultMessage="Back" />
      </S.BackButton>
    </S.BackButtonContainer>
  );
};
