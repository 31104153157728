import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function useUrlQueryParams() {
  const router = useRouter();

  function appendCustomQueryParamsToUrl(currentUrl: string | URL) {
    const url = typeof currentUrl === 'string' ? new URL(currentUrl) : currentUrl;

    // Append each query parameter to the URL if they are not already existing (except restaurantUuid and homepageUuid that are part of the URL, not part of the params)
    for (const key in router?.query) {
      if (!['restaurantUuid', 'homepageUuid'].includes(key) && !url.searchParams.has(key)) {
        url.searchParams.append(key, router.query[key] as string);
      }
    }
    return url;
  }

  return { appendCustomQueryParamsToUrl };
}

export function useSetReferrerInQueryUrl() {
  const router = useRouter();
  useEffect(() => {
    const queryParams = router.query;
    //use the current referrer as utm_source if it is not already set and it is not the same as the current hostname, remove the protocol and any query params from the referrer as well as the www. prefix
    const currentReferrer = document?.referrer ? new URL(document.referrer)?.hostname.replace(/^www\./, '') : undefined;
    if (!queryParams.utm_source && currentReferrer && currentReferrer !== window.location.hostname) {
      router.replace({ ...router, query: { ...queryParams, utm_source: currentReferrer } });
    }
  }, [router]);
}
