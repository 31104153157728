import { getConfigurationValue } from '@/utils/configuration-utils';
import { ConfigurationItemKeys } from '@/types/config';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { WidgetHomepageFragment } from '@/graphql/client-backend.generated';

export const useRestaurantDisplay = ({
  homepage,
}: {
  homepage?: WidgetHomepageFragment;
}): {
  restaurantBannerUrl: string | undefined;
  restaurantLogoUrl: string | undefined;
  shouldDisplayLanguageSelector: boolean;
  shouldDisplayRestaurantBanner: boolean;
  shouldDisplayRestaurantLogo: boolean;
  shouldDisplayRestaurantName: boolean;
  shouldDisplayTheForkLogo: boolean;
} => {
  const { widgetConfig, isWidgetCustomizable, isTfBrandingDisplayed } = useWidgetConfig();
  const shouldDisplayLanguageSelector = !homepage;

  if (!isWidgetCustomizable) {
    return {
      restaurantBannerUrl: undefined,
      restaurantLogoUrl: undefined,
      shouldDisplayLanguageSelector,
      shouldDisplayRestaurantBanner: false,
      shouldDisplayRestaurantLogo: false,
      shouldDisplayRestaurantName: true,
      shouldDisplayTheForkLogo: true,
    };
  }
  const restaurantBannerUrl = widgetConfig?.designKit?.images?.widgetBanner?.cloudinaryUrl;
  const restaurantLogoUrl = widgetConfig?.designKit?.images?.logo?.cloudinaryUrl;
  if (!!homepage) {
    return {
      restaurantBannerUrl,
      restaurantLogoUrl,
      shouldDisplayLanguageSelector,
      shouldDisplayRestaurantBanner: !!restaurantBannerUrl && homepage.configuration.useBannerImage,
      shouldDisplayRestaurantLogo: !!restaurantLogoUrl && homepage.configuration.isRestaurantLogoDisplayed,
      shouldDisplayRestaurantName: homepage?.configuration.isRestaurantNameDisplayed,
      shouldDisplayTheForkLogo: homepage?.configuration.tfBranding,
    };
  }
  return {
    restaurantBannerUrl,
    restaurantLogoUrl,
    shouldDisplayLanguageSelector,
    shouldDisplayRestaurantBanner: !!restaurantBannerUrl,
    shouldDisplayRestaurantLogo:
      !!restaurantLogoUrl &&
      getConfigurationValue(widgetConfig?.settings, ConfigurationItemKeys.IS_RESTAURANT_LOGO_DISPLAYED) === 'true',
    shouldDisplayRestaurantName:
      getConfigurationValue(widgetConfig?.settings, ConfigurationItemKeys.IS_RESTAURANT_NAME_DISPLAYED) === 'true',
    shouldDisplayTheForkLogo: isTfBrandingDisplayed,
  };
};
