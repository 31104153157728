import styled from '@emotion/styled';
import { FOR_MOBILE_ONLY } from '@/utils/emotion-utils';
import { px2rem } from '@lafourchette/design-system-theming/utils';

const BAR_WIDTH_DESKTOP = px2rem(87);
const BAR_WIDTH_MOBILE = px2rem(40);
const BAR_HEIGHT = px2rem(2);

export const ProgressBar = styled.div`
  position: relative;
  width: ${BAR_WIDTH_DESKTOP};
  height: ${BAR_HEIGHT};
  background-color: ${({ theme }) => theme.customization.colors.grey300};
  border-radius: ${({ theme }) => theme.radii.m};
  ${FOR_MOBILE_ONLY} {
    width: ${BAR_WIDTH_MOBILE};
  }
`;

export const ProgressBarFill = styled.div<{ progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ progress }) => progress}%;
  height: 100%;
  background-color: ${({ theme }) => theme.customization.colors.accent};
  border-radius: ${({ theme }) => theme.radii.m};
`;
