export const default_country = 'GB';

export const API_ERRORS = {
  OFFER_VALIDATION_FAILED: 'OFFER_VALIDATION_FAILED',
  RESTAURANT_DOES_NOT_HAVE_AVAILABILITIES: 'RESTAURANT_DOES_NOT_HAVE_AVAILABILITIES',
};

export const PUBLISHABLE_KEY_LOCALES: Record<string, string> = {
  publishable_key_thefork_at: 'de-AT',
  publishable_key_thefork_be: 'fr-BE',
  publishable_key_thefork_ch: 'fr-CH',
  publishable_key_thefork_de: 'de-DE',
  publishable_key_thefork_dk: 'da-DK',
  publishable_key_thefork_es: 'es-ES',
  publishable_key_thefork_fi: 'fi-FI',
  publishable_key_thefork_fr: 'fr-FR',
  publishable_key_thefork_gb: 'en-GB',
  publishable_key_thefork_it: 'it-IT',
  publishable_key_thefork_mc: 'fr-MC',
  publishable_key_thefork_nl: 'nl-NL',
  publishable_key_thefork_no: 'nb-NO',
  publishable_key_thefork_pl: 'pl-PL',
  publishable_key_thefork_pt: 'pt-PT',
  publishable_key_thefork_se: 'sv-SE',
} as const;
