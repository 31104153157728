import {
  IconTriangleBottom,
  IconTriangleTop,
  IconVoucher,
} from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { Voucher } from '@/graphql/types.generated';
import { useState } from 'react';
import { Message } from '@/components/Message/Message';
import { FormattedMessage } from 'react-intl';
import { Text } from '@/components/Text/Text';
import * as S from './VoucherNotice.styles';

const ToggleButton = ({ isOpen, onToggle }: { isOpen: boolean; onToggle: () => void }) => {
  if (isOpen) {
    return (
      <S.ToggleButton type="button" onClick={onToggle}>
        <FormattedMessage id="tf_widget_notice_hide" defaultMessage="HIDE" />
        <IconTriangleTop />
      </S.ToggleButton>
    );
  }
  return (
    <S.ToggleButton type="button" onClick={onToggle}>
      <FormattedMessage id="tf_widget_notice_show" defaultMessage="SHOW" />
      <IconTriangleBottom />
    </S.ToggleButton>
  );
};

type VoucherNoticeProps = {
  voucher: Pick<Voucher, 'title' | 'options' | 'pax'>;
  reservationPax: number;
};

export const VoucherNotice = ({ voucher, reservationPax }: VoucherNoticeProps) => {
  const [showMore, setShowMore] = useState(false);
  const hasMorePax = reservationPax > voucher.pax;

  return (
    <Message variant="outline" Icon={IconVoucher}>
      <Text fontWeight="m" marginY={0} variant="smallRegular">
        {hasMorePax ? (
          <FormattedMessage
            id="tf_widget_bookingWithVoucherNoticeTooMuchPax"
            defaultMessage="Some of your guests will not be covered with your Gift Voucher"
          />
        ) : (
          <FormattedMessage
            id="tf_widget_bookingWithVoucherNotice"
            defaultMessage="You are booking with your Gift Voucher"
          />
        )}
      </Text>
      {showMore && (
        <Text variant="small" marginBottom={0} marginTop="s">
          {hasMorePax && (
            <>
              <FormattedMessage
                id="tf_widget_voucherNoticeDetailsTooMuchPax"
                defaultMessage="You have selected more guests than those included in the coupon. These will be charged normally at the Restaurant."
              />
              <br />
            </>
          )}
          <FormattedMessage
            id="tf_widget_voucherNoticeDetails"
            defaultMessage="Includes: {voucherTitleAndOptions}"
            values={{ voucherTitleAndOptions: [voucher.title, ...voucher.options].join(' + ') }}
          />
        </Text>
      )}
      <ToggleButton isOpen={showMore} onToggle={() => setShowMore((current) => !current)} />
    </Message>
  );
};
