import { FormattedMessage, useIntl } from 'react-intl';
import { Message } from '@/components/Message/Message';
import { minutesToHours } from 'date-fns';
import { timeSlotToDate } from '@/utils/date-utils';
import { useMemo } from 'react';

export type SeatingTimeMessageProps = {
  mealDuration: number;
  mealStart: number;
};

export const SeatingTimeMessage = ({ mealDuration, mealStart }: SeatingTimeMessageProps) => {
  const intl = useIntl();

  const { mealDurationText } = useMemo(() => {
    const hours = minutesToHours(mealDuration);
    const minutes = mealDuration % 60;
    const list = [];
    if (hours > 0) {
      list.push(intl.formatNumber(hours, { style: 'unit', unit: 'hour' }));
    }
    if (minutes > 0) {
      list.push(intl.formatNumber(minutes, { style: 'unit', unit: 'minute' }));
    }
    return {
      mealDurationText: intl.formatList(list, { type: 'unit' }),
      mealDuration: mealDuration,
    };
  }, [intl, mealDuration]);

  return (
    <Message variant="information">
      <FormattedMessage
        id="tf_widget_seating_table_booked_for_hours_minutes_v2"
        defaultMessage="Your table will be booked for {mealDurationText} from {mealDurationStart} to {mealDurationEnd}."
        values={{
          mealDurationText: mealDurationText,
          mealDurationStart: intl.formatTime(timeSlotToDate(mealStart)),
          mealDurationEnd: intl.formatTime(timeSlotToDate(mealStart + mealDuration)),
        }}
      />
    </Message>
  );
};
