import { Text } from '@/components/Text/Text';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';

export const HeaderOptInWrapper = styled(
  (props: ComponentProps<typeof Text> & { isDHP?: boolean; hasBackground?: boolean }) => (
    <Text as="span" variant="smallRegular" {...props} />
  ),
  {
    shouldForwardProp: (props) => !['isDHP', 'hasBackground'].includes(props),
  },
)`
  opacity: ${({ isDHP }) => (isDHP ? '0' : '1')};
  color: ${({ hasBackground, theme }) =>
    hasBackground ? theme.customization.colors.text.inverted : theme.customization.colors.text.default} !important;
  transition: opacity 1s;
  display: -webkit-inline-box;
  flex-direction: row;
  text-align: center;

  & img {
    position: relative;
    top: 1.5px;
  }
`;
