import { timeslotToTimeHHMM } from '@/utils/date-utils';
import { GetOfferDocument, GetOfferQuery } from '@/graphql/client-backend.generated';
import { useDefaultDhpParams, useBooking } from '@/hooks/useBooking';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { BookingSummary } from '@/components/BookingSummary/BookingSummary';
import { A_LA_CARTE_OFFER } from '@/components/ContactForm/ContactForm';
import { SkeletonRect } from '@/components/SkeletonRect/SkeletonRect';
import * as S from './WizardStepperRecap.styles';

export const WizardStepperRecap = () => {
  const { bookingParams, restaurantUuid } = useBooking();
  const defaultDhpParams = useDefaultDhpParams();

  const [offerData, setOfferData] = useState<GetOfferQuery | undefined>();
  const [menuName, setMenuName] = useState<string | undefined>();
  const [menuCurrency, setMenuCurrency] = useState<string | undefined>();
  const [menuPrice, setMenuPrice] = useState<number | undefined>();

  const { loading, error } = useQuery(GetOfferDocument, {
    variables: {
      restaurantUuid: restaurantUuid,
      offerUuid: bookingParams.offer || '',
    },
    skip: !bookingParams.offer || bookingParams.offer === A_LA_CARTE_OFFER,
    onCompleted: (data: GetOfferQuery) => setOfferData(data),
  });

  useEffect(() => {
    setOfferData(undefined);
  }, []);

  useEffect(() => {
    const menu = offerData?.restaurant?.offer;
    if (menu) {
      const maxNameLength = 70;
      const name = menu.name.length > maxNameLength ? menu.name.substring(0, maxNameLength) + '...' : menu.name;
      setMenuName(name);
      setMenuCurrency(menu.presetMenu.price?.currency);
      setMenuPrice(menu.presetMenu.price?.value);
    }
  }, [offerData?.restaurant?.offer]);

  if (error) {
    throw new Error(error.message);
  }

  return (
    <S.BookingSummaryContainer>
      {loading && <SkeletonRect height={103} />}
      {!loading && (
        <BookingSummary
          pax={bookingParams.pax || defaultDhpParams.pax}
          date={bookingParams.date || defaultDhpParams.date}
          hour={timeslotToTimeHHMM(Number(bookingParams.time ?? defaultDhpParams.time))}
          hasOffer={!!menuName}
          offerName={menuName}
          offerPrice={menuPrice}
          offerCurrency={menuCurrency}
          offerCenteredStyle={true}
        />
      )}
    </S.BookingSummaryContainer>
  );
};
