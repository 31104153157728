import {
  IconExclamationmarkCircleFill,
  IconExclamationmarkTriangleFill,
  IconInformationCircleFill,
} from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { MessageProps, MessageVariant } from './Message';
import { Theme } from '@emotion/react';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import styled from '@emotion/styled';

const getBackgroundColor = ({ theme, variant }: { theme: Theme; variant: MessageVariant }) => {
  if (variant === 'outline') {
    return theme.customization.colors.background100;
  }
  return theme.customization.colors.message.background;
};

const getColor = ({ theme, variant }: { theme: Theme; variant: MessageVariant }) => {
  if (variant === 'outline') {
    return theme.customization.colors.text.default;
  }
  return theme.customization.colors.message.text;
};

const getBorder = ({ theme, variant }: { theme: Theme; variant: MessageVariant }) => {
  if (variant === 'outline') {
    return `1px solid ${theme.customization.colors.text.onCta.secondary.default}`;
  }
};

export const Container = styled(Flex)<{ variant: MessageVariant }>`
  word-break: break-word;
  width: 100%;
  border: ${getBorder};
  padding: ${({ theme }) => theme.space.m};
  border-radius: ${({ theme }) => theme.radii.m};
  background-color: ${getBackgroundColor};
  color: ${getColor};
  font-family: ${({ theme }) => theme.customization.fonts.body};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const FlexC = styled(Flex)`
  width: 100%;
`;

const Icon = ({ variant, className, Icon }: Pick<MessageProps, 'variant' | 'Icon'> & { className?: string }) => {
  if (Icon) return <Icon className={className} fontSize="s" />;
  if (variant === 'information') return <IconInformationCircleFill className={className} fontSize="s" />;
  if (variant === 'alert') return <IconExclamationmarkCircleFill className={className} fontSize="s" />;
  return <IconExclamationmarkTriangleFill className={className} fontSize="s" />;
};

export const MessageIcon = styled(Icon)`
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.space.s};
`;
