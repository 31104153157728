import { IntlShape } from 'react-intl';
import parseMax from 'libphonenumber-js/max';
import { tlds } from '@hapi/tlds';
import { z } from 'zod';

/*
  ZOD is NOT enabling by default TLDs validation for email addresses.
  Here we are using a list of TLDs from @hapi/tlds to validate email addresses against IANA authorized TLDs
*/
const emailValidator = (value?: string) => {
  if (!value) {
    return false;
  }
  const [, afterAtPart] = value.split('@');
  const domainParts = afterAtPart?.split('.');
  if (!domainParts?.length) {
    return false;
  }
  const emailTld = domainParts[domainParts.length - 1].toLowerCase();
  return tlds.has(emailTld);
};

export const getSchema = (intl: IntlShape) => {
  return z.object({
    customCivility: z.string().nullish(),
    civilitySelector: z.string().nullish(),
    civility: z.string().nullish(),
    firstName: z
      .string({
        message: intl.formatMessage({
          id: 'tf_widget_error_firstName_required',
          defaultMessage: 'First name required',
        }),
      })
      .trim()
      .min(
        1,
        intl.formatMessage({
          id: 'tf_widget_error_firstName_required',
          defaultMessage: 'First name required',
        }),
      )
      .max(
        35,
        intl.formatMessage({
          id: 'tf_widget_error_firstName_too_long',
          defaultMessage: 'First name exceeded 35 characters',
        }),
      ),
    lastName: z
      .string({
        message: intl.formatMessage({
          id: 'tf_widget_error_lastName_required',
          defaultMessage: 'Last name required',
        }),
      })
      .trim()
      .min(
        1,
        intl.formatMessage({
          id: 'tf_widget_error_lastName_required',
          defaultMessage: 'Last name required',
        }),
      )
      .max(
        35,
        intl.formatMessage({
          id: 'tf_widget_error_lastName_too_long',
          defaultMessage: 'Last name exceeded 35 characters',
        }),
      ),
    email: z
      .string({
        message: intl.formatMessage({
          id: 'tf_widget_error_email_required',
          defaultMessage: 'Email address is required',
        }),
      })
      .min(
        1,
        intl.formatMessage({
          id: 'tf_widget_error_email_required',
          defaultMessage: 'Email address is required',
        }),
      )
      .email(
        intl.formatMessage({
          id: 'tf_widget_error_email_invalid',
          defaultMessage: 'Invalid Email address',
        }),
      )
      .refine(emailValidator, {
        message: intl.formatMessage({
          id: 'tf_widget_error_email_invalid',
          defaultMessage: 'Invalid Email address',
        }),
      }),
    phone: z
      .string({
        message: intl.formatMessage({
          id: 'tf_widget_error_phone_required',
          defaultMessage: 'Phone number is required',
        }),
      })
      .min(
        1,
        intl.formatMessage({
          id: 'tf_widget_error_phone_required',
          defaultMessage: 'Phone number is required',
        }),
      )
      .refine((value?: string) => (value ? !!parseMax(value)?.isValid() : false), {
        message: intl.formatMessage({
          id: 'tf_widget_error_phone_invalid',
          defaultMessage: 'Invalid Phone number',
        }),
      }),
    restaurantNewsletterOptIn: z.boolean().nullish(),
    theForkNewsletterOptIn: z.boolean().nullish(),
  });
};
