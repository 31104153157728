import styled from '@emotion/styled';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import { Heading } from '@/components/Heading/Heading';
import {
  IconCalendar,
  IconClock,
  IconPersonCard,
  IconPersons,
  IconRestaurantTray,
} from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';

export { IconCalendar, IconClock, IconPersonCard, IconPersons, IconRestaurantTray };

export const TitleBox = styled(Box)`
  display: grid;
  gap: ${({ theme }) => theme.space.s};
  padding: ${({ theme }) => `${theme.space.l} ${theme.space.m}`};
`;

export const Title = styled(Heading)`
  color: ${({ theme }) => theme.customization.colors.text.default};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.customization.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.m};
  padding: 0;
  gap: ${({ theme }) => theme.space.s};
  margin: 0;
`;

export const HeaderTitleBox = styled(TitleBox)`
  grid-area: head;
  padding-bottom: ${({ theme }) => theme.space.m};
`;

export const TitleContext = styled(Box)`
  color: ${({ theme }) => theme.customization.colors.text.default};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.s};
`;
