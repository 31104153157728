import { Button } from '@/components/Button/Button';
import styled from '@emotion/styled';

export const DhpButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 ${({ theme }) => theme.space.s};
  background-color: ${({ theme }) => theme.customization.colors.cta.secondary.default};
  color: ${({ theme }) => theme.customization.colors.text.onCta.secondary.default} !important;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  cursor: pointer;
  border: 0;
  width: 100%;
  flex: 1;
  border-radius: 2px !important;
  overflow: hidden; // to support the shrunk version of the button on small screens
`;

export const DhpButtonContent = styled.div`
  text-transform: capitalize;
  padding: 0 ${({ theme }) => theme.space.xs};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
