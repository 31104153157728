import { useController, UseControllerProps } from 'react-hook-form';
import * as S from '@/react-chili/PhoneSelect/PhoneSelect.styles';
import { ContactInformation } from '@/components/ContactForm/ContactForm';

export type ChiliSelectProps = {
  prefix: string;
  countryCode: string;
  label: string;
  placeholder?: string;
  required?: boolean;
};

type PhoneSelectProps = UseControllerProps<ContactInformation> & ChiliSelectProps;

export function PhoneSelect({ prefix, label, countryCode, placeholder, required, ...props }: PhoneSelectProps) {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    field: { value, ref, ...field },
    fieldState: { error },
  } = useController(props);

  return (
    <S.PhoneSelect
      {...field}
      countryCode={countryCode}
      error={error?.message}
      label={label}
      prefix={`${prefix}-${field.name}`}
      placeholder={placeholder}
      required={required}
    ></S.PhoneSelect>
  );
}
