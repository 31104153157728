import React from 'react';

import * as S from './RequiredLegend.styles';
import { FormattedMessage } from 'react-intl';

export const RequiredLegend: React.FC = () => (
  <S.RequiredLegend>
    <S.RequiredAsterisk />
    <FormattedMessage id="tf_widget_contact_required" defaultMessage="Required" />
  </S.RequiredLegend>
);
