import { useTheme } from '@emotion/react';
import svgLogo from '@/assets/icons/TF-logo-horizontal.svg';
import svgLogoWhite from '@/assets/icons/TF-logo-white-horizontal.svg';

export type UseTheForkLogoParams = {
  isFooter?: boolean;
  hasBackground?: boolean;
};

export const useTheForkLogo = ({ isFooter = false, hasBackground = false }: UseTheForkLogoParams) => {
  const theme = useTheme();

  const isDarkText = theme.customization.colors.text.default === '#000000';

  if (isFooter) {
    return svgLogo.src;
  }
  if (hasBackground) {
    return svgLogoWhite.src;
  }
  return isDarkText ? svgLogo.src : svgLogoWhite.src;
};
