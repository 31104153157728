import { WizardStep } from '@/types/WizardBooking';
import { BELOW_MOBILE_LANDSCAPE, FOR_MOBILE_ONLY, FOR_TABLET_AND_MORE } from '@/utils/emotion-utils';
import { Heading } from '@/components/Heading/Heading';
import { px2em } from '@lafourchette/design-system-theming/utils';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';

const BANNER_HEIGHT = px2em(80);

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.customization.colors.background};
  color: ${({ theme }) => theme.customization.colors.text.default};
  font-family: ${({ theme }) => theme.customization.fonts.body};
  min-height: 100vh;

  *::selection {
    background-color: ${({ theme }) => theme.customization.colors.text.selectionBackground} !important;
    color: ${({ theme }) => theme.customization.colors.text.selection};
  }
`;

export const MainContentBox = styled(Box)<{ step: WizardStep }>`
  max-width: calc(
    2 * ${({ theme }) => theme.space.m} +
      ${({ step }) => {
        switch (step) {
          case WizardStep.Offer:
            return MainContentBoxMaxWidth.L;
          case WizardStep.UserInformation:
          case WizardStep.MoreInfo:
          case WizardStep.Payment:
            return MainContentBoxMaxWidth.M;
          case WizardStep.Success:
          case WizardStep.Result:
            return MainContentBoxMaxWidth.Full;
          default:
            return MainContentBoxMaxWidth.S;
        }
      }}
  );
  width: 100%;
  display: flex;
  padding: ${({ theme }) => theme.space.m};
`;

export enum MainContentBoxMaxWidth {
  S = '480px',
  M = '600px',
  L = '780px',
  Full = '100%',
}

export const FixedHeader = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.customization.colors.background};
  z-index: 10;
  position: static;

  @media (min-height: 768px) {
    position: sticky;
    top: 0;
  }
`;

export const GridHeader = styled.div<{ isDHP?: boolean; showBanner?: boolean }>`
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  transition: all 1s;
  grid-template-rows: ${({ isDHP, showBanner = true }) => (isDHP ? '0 0' : `${showBanner ? BANNER_HEIGHT : '0em'} 3em`)} auto auto;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;

  grid-template-areas:
    'name name name name name'
    'back back logo lang lang'
    'head head head head head'
    'dhpf dhpf dhpf dhpf dhpf';

  ${FOR_TABLET_AND_MORE} {
    grid-template-rows: ${({ isDHP, showBanner = true }) => (isDHP ? '0 0' : `${showBanner ? BANNER_HEIGHT : '0em'} 3em`)} auto auto;
    grid-template-areas:
      'logo name name name .   '
      'back .    .    .    lang'
      'head head head head head'
      'dhpf dhpf dhpf dhpf dhpf';
  }
}`;

export const HeaderLogo = styled.img<{ isDHP?: boolean }>`
  z-index: 1;
  grid-area: logo;
  opacity: ${({ isDHP }) => (isDHP ? '0' : '1')};
  transition: opacity 1s;
  justify-self: center;
  align-self: center;
  max-width: 65px;
  max-height: 65px;
  ${BELOW_MOBILE_LANDSCAPE} {
    max-width: 2.5em;
    max-height: 2.5em;
  }
`;

export const HeaderTitleWrapper = styled(
  (props: ComponentProps<typeof Box> & { hasBanner?: boolean }) => (
    <Box display="flex" flexDirection="column" alignItems="center" {...props} />
  ),
  {
    shouldForwardProp: (props) => !['hasBanner'].includes(props),
  },
)`
  grid-area: name;
  z-index: 1;
  color: ${({ hasBanner, theme }) => (hasBanner ? '#ffffff' : theme.customization.colors.text.default)};
`;

export const BackButtonContainer = styled(Box)`
  grid-area: back;
`;

export const LanguageSelectorContainer = styled(Box)<{ isDHP?: boolean }>`
  opacity: ${({ isDHP }) => (isDHP ? '0' : '1')};
  z-index: 3;
  grid-area: lang;
  padding-right: ${({ theme }) => theme.space.m};
  ${FOR_MOBILE_ONLY} {
    justify-self: flex-end;
  }
`;

export const WizardStepperContainer = styled.div`
  width: 100%;
  grid-area: dhpf;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  ${FOR_MOBILE_ONLY} {
    padding-left: ${({ theme }) => theme.space.m};
    padding-right: ${({ theme }) => theme.space.m};
  }
`;

export const HeaderTitle = styled(
  (props: ComponentProps<typeof Heading> & { isDHP?: boolean; hasBackground?: boolean }) => (
    <Heading m={0} mt={12} {...props} />
  ),
  {
    shouldForwardProp: (props) => !['isDHP', 'hasBackground'].includes(props),
  },
)`
  opacity: ${({ isDHP }) => (isDHP ? '0' : '1')};
  color: ${({ hasBackground, theme }) =>
    hasBackground ? theme.customization.colors.text.inverted : theme.customization.colors.text.default} !important;
  transition: opacity 1s;
  text-align: center;
`;

export const RestaurantVisual = styled(Box)<{ bannerUrl?: string; isDHP?: boolean }>`
  opacity: ${({ isDHP }) => (isDHP ? '0' : '1')};
  transition: opacity 1s;
  position: absolute;
  top: 0;
  background: ${({ bannerUrl, theme }) =>
    bannerUrl
      ? `linear-gradient(270deg, rgba(0, 0, 0, 0.4) 57.81%, rgba(0, 0, 0, 0) 100%), url('${bannerUrl}')`
      : theme.customization.colors.background};
  background-size: cover;
  background-position: center;
  min-height: ${BANNER_HEIGHT};
  width: 100%;
`;

export const MentionWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${({ theme }) => `${theme.space.m} ${theme.space.m} 0 ${theme.space.m}`};
`;
