import { WizardStep } from '@/types/WizardBooking';
import { Message } from '@/components/Message/Message';
import styled from '@emotion/styled';

enum MainContentBoxMaxWidth {
  S = '480px',
  M = '600px',
  L = '780px',
  Full = '100%',
}

export const SnackWrapper = styled(Message)<{ step: WizardStep }>`
  width: 100%;
  word-break: break-word;
  border-radius: 0.25em;
  max-width: ${({ step }) => {
    switch (step) {
      case WizardStep.Offer:
        return MainContentBoxMaxWidth.L;
      case WizardStep.UserInformation:
      case WizardStep.Payment:
        return MainContentBoxMaxWidth.M;
      default:
        return MainContentBoxMaxWidth.S;
    }
  }};
`;

export const Secondary = styled.div`
  padding: ${({ theme }) => theme.space.s} 0;
`;

export const ToggleButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-top: ${({ theme }) => theme.space.s};
`;
