import styled from '@emotion/styled';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import { FOR_MOBILE_ONLY } from '@/utils/emotion-utils';
import { px2rem } from '@lafourchette/design-system-theming/utils';

const STEPPER_MAX_WIDTH = px2rem(600);

export const UserCheckoutStepperContainer = styled(Box)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const UserCheckoutStepper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.space.m};
  gap: ${({ theme }) => theme.space.halfL};
  max-width: ${STEPPER_MAX_WIDTH};
  ${FOR_MOBILE_ONLY} {
    gap: ${({ theme }) => theme.space.s};
    padding-bottom: ${({ theme }) => theme.space.halfXxl};
  }
`;
