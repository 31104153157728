import { Select } from '@/components/Select/Select';
import styled from '@emotion/styled';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import { ComponentProps } from 'react';
import { OptionValue } from './PhoneCountryCodeSelect.types';
import { px2rem } from '@lafourchette/design-system-theming/utils';

export const StyledSelect = styled(Select<OptionValue>)`
  flex-shrink: 0;
  & > div {
    position: static;
  }
  width: ${px2rem(85)};
`;

export const OptionWrapper = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CountryName = styled((props: ComponentProps<typeof Box>) => <Box as="span" {...props} />)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CountryPrefix = styled((props: ComponentProps<typeof Box>) => <Box as="span" {...props} />)``;

export const CountryFlag = styled((props: ComponentProps<typeof Box>) => <Box as="span" {...props} />)`
  width: ${({ theme }) => theme.space.m};
  height: ${({ theme }) => theme.space.m};
  margin-right: ${({ theme }) => theme.space.s};
`;
