import { FOR_DESKTOP_ONLY } from '@/utils/emotion-utils';
import styled from '@emotion/styled';

export const BookingSummaryContainer = styled.div`
  width: 100%;
  max-width: 600px;
  ${FOR_DESKTOP_ONLY} {
    margin: 0 ${({ theme }) => theme.space.m};
  }
`;
