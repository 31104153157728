import { WizardStep } from '@/types/WizardBooking';
import { useBooking } from '@/hooks/useBooking';
import { DhpSectionName, FilterDhpButtons } from '@/components/FilterDhpButtons/FilterDhpButtons';
import { WizardStepperRecap } from './StepperRecap/WizardStepperRecap';
import * as S from './WizardStepper.styles';

export const WizardStepper: React.FC<{ isCouponEnabled: boolean }> = ({ isCouponEnabled }) => {
  const { handleSelectStep, step } = useBooking();
  const hasDHP = ![WizardStep.UserInformation, WizardStep.MoreInfo, WizardStep.Payment].includes(step);

  return (
    <S.StepperBox>
      {hasDHP && (
        <FilterDhpButtons
          isCouponEnabled={isCouponEnabled}
          onDhpButtonClick={(section: DhpSectionName) => {
            handleSelectStep({ step: String(section) === step ? WizardStep.Blank : String(section) });
          }}
        />
      )}
      {!hasDHP && <WizardStepperRecap />}
    </S.StepperBox>
  );
};
