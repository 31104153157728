import React from 'react';
import { Modal } from '@/components/Modal/Modal';
import { Button } from '@/components/Button/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import * as S from './LeavePaymentStepModal.styles';

type LeavePaymentStepModalProps = {
  handleModalClose: () => void;
  handleModalCloseAndGoBack: () => void;
};

export const LeavePaymentStepModal: React.FC<LeavePaymentStepModalProps> = ({
  handleModalClose,
  handleModalCloseAndGoBack,
}) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'tf_widget_booking_cc_error_modal_title',
        defaultMessage: 'Warning',
      })}
      onClose={() => handleModalClose()}
    >
      <S.LeavePaymentStepModalContent data-testid="leave-payment-step-modal-content">
        <S.LeavePaymentStepModalTitle>
          <FormattedMessage
            id="tf_widget_booking_cc_leave_modal_confirmation"
            defaultMessage="Are you sure you want to leave?"
          />
        </S.LeavePaymentStepModalTitle>
        <S.LeavePaymentStepModalText>
          <FormattedMessage
            id="tf_widget_booking_cc_leave_modal_message"
            defaultMessage="Your booking will not be confirmed without your credit card details. You will be redirected to the previous page."
          />
        </S.LeavePaymentStepModalText>
        <S.LeavePaymentStepModalButtonContainer>
          <Button variant="primary" mb="m" onClick={() => handleModalClose()}>
            <FormattedMessage id="tf_widget_booking_cc_leave_modal_button_continue" defaultMessage="Continue booking" />
          </Button>
          <Button variant="secondary" onClick={() => handleModalCloseAndGoBack()}>
            <FormattedMessage
              id="tf_widget_booking_cc_leave_modal_button_back"
              defaultMessage="Back to previous page"
            />
          </Button>
        </S.LeavePaymentStepModalButtonContainer>
      </S.LeavePaymentStepModalContent>
    </Modal>
  );
};
