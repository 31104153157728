import { Theme, useTheme } from '@emotion/react';
import { SelectContainer } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/SelectComponents';
import SelectV3 from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/SelectV3/SelectV3';
import { ComponentProps } from 'react';
import { CSSObjectWithLabel, OptionProps, StylesConfig, components } from 'react-select';
import * as S from './Select.styles';

const SelectOption = <Option, IsMulti extends boolean>({ children, ...props }: OptionProps<Option, IsMulti>) => (
  <S.StyledSelectOptionWrapper isSelected={props.isSelected} isFocused={props.isFocused}>
    <components.Option {...props}>{children}</components.Option>
  </S.StyledSelectOptionWrapper>
);

const getControlStyles: <Option, IsMulti extends boolean>(
  theme: Theme,
  status: ComponentProps<typeof SelectContainer>['status'],
) => StylesConfig<Option, IsMulti>['control'] =
  (theme, status) =>
  (base, props): CSSObjectWithLabel => {
    return {
      ...SelectV3.defaultStyles.getControlStyles(theme, status)(base, props as any), // eslint-disable-line @typescript-eslint/no-explicit-any
      transition: `border-color ${theme.duration.m}`,
      borderBottomWidth: theme.borderWidths.m,
      borderBottomStyle: 'solid',
      backgroundColor: theme.customization.colors.background,
      fontFamily: theme.customization.fonts.body,
      paddingBottom: 0,
      marginTop: '-2px !important',
      ...(props.isDisabled
        ? {
            background: theme.customization.colors.background,
            borderBottomColor: `${theme.customization.colors.fields.disabled} !important;`,
          }
        : props.menuIsOpen
          ? {
              borderBottomColor: `${theme.customization.colors.fields.pressed} !important`,
            }
          : props.isFocused
            ? {
                borderBottomColor: `${theme.customization.colors.fields.pressed} !important`,
                '&:hover': {
                  borderBottomColor: `${theme.customization.colors.fields.pressed} !important`,
                },
              }
            : {
                borderBottomColor: `${theme.customization.colors.fields.border} !important`,
                '&:hover': {
                  borderBottomColor: `${theme.customization.colors.fields.hover} !important`,
                },
              }),
      ...(props.isDisabled && {
        svg: {
          color: `${theme.customization.colors.fields.disabled} !important`,
        },
      }),
    };
  };

const getMenuStyles: <Option, IsMulti extends boolean>(
  theme: Theme,
) => NonNullable<StylesConfig<Option, IsMulti>['menu']> = (theme) => (base, props) => ({
  ...SelectV3.defaultStyles.getMenuStyles(theme)(base, props as any), // eslint-disable-line @typescript-eslint/no-explicit-any
  backgroundColor: theme.customization.colors.background,
  boxShadow: 'none !important',
});

const getMenuListStyles: <Option, IsMulti extends boolean>() => NonNullable<
  StylesConfig<Option, IsMulti>['menuList']
> = () => (base, props) => ({
  ...SelectV3.defaultStyles.getMenuListStyles()(base, props as any), // eslint-disable-line @typescript-eslint/no-explicit-any
});

const getPlaceholderStyles: <Option, IsMulti extends boolean>(
  theme: Theme,
) => NonNullable<StylesConfig<Option, IsMulti>['placeholder']> = (theme) => (base, props) => ({
  ...SelectV3.defaultStyles.getPlaceholderStyles(theme)(base, props as any), // eslint-disable-line @typescript-eslint/no-explicit-any
  color: theme.customization.colors.text.onFields,
  fontFamily: theme.customization.fonts.body,
  ...(props.isDisabled && {
    color: theme.customization.colors.text.default,
  }),
});

const getValueContainerStyles: <Option, IsMulti extends boolean>() => NonNullable<
  StylesConfig<Option, IsMulti>['valueContainer']
> = () => (base) => ({
  ...base,
  padding: 0,

  '> div': {
    marginTop: 0,
    marginBottom: 0,
  },
});

const getInputStyles: <Option, IsMulti extends boolean>(
  theme: Theme,
) => NonNullable<StylesConfig<Option, IsMulti>['input']> = (theme) => (base, props) => ({
  ...base,
  color: theme.customization.colors.text.onFields,
  fontFamily: theme.customization.fonts.body,
  ...(props.isDisabled && {
    color: theme.customization.colors.text.default,
  }),
});

const getSingleValueStyles: <Option, IsMulti extends boolean>(
  theme: Theme,
) => NonNullable<StylesConfig<Option, IsMulti>['singleValue']> = (theme) => (base) => ({
  ...base,
  color: theme.customization.colors.text.onFields,
  fontFamily: theme.customization.fonts.body,
});

export const Select = <ValueType,>(props: ComponentProps<typeof SelectV3<ValueType>> & { isFullWidth?: boolean }) => {
  const theme = useTheme();
  return (
    <S.WrapperOverride isDisabled={props.isDisabled} isFullWidth={props.isFullWidth}>
      <SelectV3<ValueType>
        flexGrow
        {...props}
        components={{ Option: SelectOption }}
        styles={{
          control: getControlStyles(theme, props.status),
          menu: getMenuStyles(theme),
          menuList: getMenuListStyles(),
          placeholder: getPlaceholderStyles(theme),
          valueContainer: getValueContainerStyles(),
          input: getInputStyles(theme),
          singleValue: getSingleValueStyles(theme),
        }}
      />
    </S.WrapperOverride>
  );
};
