import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import styled from '@emotion/styled';

export const StepperBox = styled(Box)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
