import { IconTriangleBottom, IconTriangleTop } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Message } from '@/components/Message/Message';
import * as S from './PaymentNotice.styles';

export enum PaymentType {
  IMPRINT = 'imprint',
  PREPAYMENT = 'prepayment',
}

export type PaymentNoticeProps = {
  paymentType: PaymentType;
  amount: number;
  currency: string;
  isAutoRefundable: boolean;
};

export const PaymentNotice: React.FC<PaymentNoticeProps> = ({
  paymentType,
  amount,
  currency,
  isAutoRefundable,
}: PaymentNoticeProps) => {
  const intl = useIntl();
  const [isDetailOpened, setIsDetailOpened] = useState(false);

  return (
    <S.Container>
      <Message variant="warning">
        <S.Title>
          {paymentType === PaymentType.IMPRINT
            ? intl.formatMessage({
                id: 'tf_widget_notice_imprint_required_title',
                defaultMessage: 'Credit Card details will be required by the restaurant',
              })
            : intl.formatMessage(
                {
                  id: 'tf_widget_notice_prepayment_required_title',
                  defaultMessage: 'A pre-payment of {amount} {currency} per person is required',
                },
                { amount, currency },
              )}
        </S.Title>
        {isDetailOpened && (
          <S.Details>
            {paymentType === PaymentType.IMPRINT &&
              intl.formatMessage({
                id: 'tf_widget_notice_imprint_required_details',
                defaultMessage:
                  'You must provide your card details to secure your reservation. Your card will not be charged now. Before entering your card details, please read the Credit Card Imprint Conditions carefully.',
              })}
            {paymentType === PaymentType.PREPAYMENT &&
              (isAutoRefundable
                ? intl.formatMessage({
                    id: 'tf_widget_notice_prepayment_required_standard_details',
                    defaultMessage:
                      'The pre-payment functionality is operated by the restaurant, NOT by TheFork. The prepaid reservation will be FINAL, NON-REFUNDABLE, NON-EXCHANGEABLE and NON-TRANSFERABLE, except at the discretion of the restaurant (contact the restaurant for more information). Before entering your card details, please read the Pre-payment Conditions carefully.',
                  })
                : intl.formatMessage({
                    id: 'tf_widget_notice_prepayment_required_custom_details',
                    defaultMessage:
                      'The pre-payment functionality is operated by the restaurant, NOT by TheFork. The prepaid reservation will be NON-TRANSFERABLE. Before entering your card details, please read the Pre-payment Conditions carefully.',
                  }))}
          </S.Details>
        )}
        <div>
          {isDetailOpened ? (
            <S.ToggleButton type="button" onClick={() => setIsDetailOpened(!isDetailOpened)}>
              {intl.formatMessage({
                id: 'tf_widget_notice_hide',
                defaultMessage: 'HIDE',
              })}
              <IconTriangleTop />
            </S.ToggleButton>
          ) : (
            <S.ToggleButton type="button" onClick={() => setIsDetailOpened(!isDetailOpened)}>
              {intl.formatMessage({
                id: 'tf_widget_notice_show',
                defaultMessage: 'SHOW',
              })}
              <IconTriangleBottom />
            </S.ToggleButton>
          )}
        </div>
      </Message>
    </S.Container>
  );
};
