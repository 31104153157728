import { useTheme } from '@emotion/react';
import ContentLoader from 'react-content-loader';

export type SkeletonRectParams = {
  borderRadius?: number | string;
  height?: number | string;
  uniqueKey?: string;
  width?: number | string;
};

export const SkeletonRect = (params: SkeletonRectParams) => {
  const theme = useTheme();
  return (
    <ContentLoader
      backgroundColor={theme.customization.colors.grey200}
      foregroundColor={theme.customization.colors.grey300}
      width={params.width || '100%'}
      height={params.height || 50}
      speed={1}
      title=""
      uniqueKey={`skeleton-${params.uniqueKey || '1'}`}
      data-testid={`skeleton-${params.uniqueKey || '1'}`}
    >
      <rect width="100%" height="100%" rx={params.borderRadius || 4} />
    </ContentLoader>
  );
};
