import { WizardStep } from '@/types/WizardBooking';
import { dateStrToDateNoTZ, timeSlotToDate } from '@/utils/date-utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateString } from './DateString/DateString';
import { DhpSection } from './DhpSection/DhpSection';
import { useDefaultDhpParams, useBooking } from '@/hooks/useBooking';
import { IconMagnifyingglass } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { CouponCode } from '@/components/CouponCode/CouponCode';
import { CouponCodePill } from '@/components/CouponCodePill/CouponCodePill';
import * as S from './FilterDhpButtons.styles';

export enum DhpSectionName {
  Date = WizardStep.Date,
  Hour = WizardStep.Hour,
  Pax = WizardStep.Pax,
}

export interface DhpProps {
  onDhpButtonClick: (sectionName: DhpSectionName) => void;
  isCouponEnabled?: boolean;
}

export const FilterDhpButtons = ({ onDhpButtonClick, isCouponEnabled = false }: DhpProps) => {
  const intl = useIntl();
  const { handleSelectStep, bookingParams, step } = useBooking();
  const defaultDhpParams = useDefaultDhpParams();

  const date = dateStrToDateNoTZ(bookingParams.date ?? defaultDhpParams.date);
  const hour = intl.formatTime(timeSlotToDate(Number(bookingParams.time ?? defaultDhpParams.time)));
  const pax = bookingParams.pax ?? defaultDhpParams.pax;
  const isDisabledSearchStep = [WizardStep.Result, WizardStep.Offer].includes(step);

  return (
    <S.FlexC>
      <CouponCodePill currentPax={pax} />
      <S.DhpButtonsContainer>
        <DhpSection
          testId="filter-button-dhp-pax"
          Icon={() => <S.IconPerson data-testid="person-icon" />}
          onClick={() => onDhpButtonClick(DhpSectionName.Pax)}
          isSelected={step === String(DhpSectionName.Pax)}
          displayChevron={false}
        >
          <FormattedMessage
            id="tf_widget_booking_paxs_abbrv"
            defaultMessage="{nPartySize, plural, one{one pers} other{# pers}}"
            values={{ nPartySize: pax }}
          />
        </DhpSection>
        <S.VerticalSeparator />
        <DhpSection
          testId="filter-button-dhp-date"
          Icon={() => <S.IconCalendar data-testid="calendar-icon" />}
          onClick={() => onDhpButtonClick(DhpSectionName.Date)}
          isSelected={step === String(DhpSectionName.Date)}
          displayChevron={false}
        >
          <DateString date={date} />
        </DhpSection>
        <S.VerticalSeparator />
        <DhpSection
          testId="filter-button-dhp-hour"
          Icon={() => <S.IconClock data-testid="clock-icon" />}
          onClick={() => onDhpButtonClick(DhpSectionName.Hour)}
          isSelected={step === String(DhpSectionName.Hour)}
          displayChevron={false}
        >
          {hour}
          {bookingParams.time != null && Number(bookingParams.time) >= 1440 && ` (+1)`}
        </DhpSection>
      </S.DhpButtonsContainer>
      <S.SearchButtonAndVoucher>
        <S.SearchButtonContainer>
          <S.SearchButton
            isSmall
            data-testid="search-button"
            disabled={isDisabledSearchStep}
            onClick={() =>
              handleSelectStep({
                step: WizardStep.Result,
                date: bookingParams.date,
                pax: bookingParams.pax,
                time: bookingParams.time,
              })
            }
          >
            <IconMagnifyingglass /> <FormattedMessage id="tf_widget_header_find_table" defaultMessage="Find a table" />
          </S.SearchButton>
        </S.SearchButtonContainer>
        {isCouponEnabled && step !== WizardStep.CouponRecap ? <CouponCode /> : null}
      </S.SearchButtonAndVoucher>
    </S.FlexC>
  );
};
