import React, { ComponentPropsWithRef, InputHTMLAttributes } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import ChiliInput from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/Input';
import * as S from './Input.styles';

export type InputProps<T extends FieldValues> = {
  label: React.ReactNode;
  prefix: string;
} & UseControllerProps<T> &
  ComponentPropsWithRef<typeof ChiliInput> &
  InputHTMLAttributes<T>;

export const Input = <T extends FieldValues>({ prefix, label, required, ...props }: InputProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return (
    <S.ContainerOverride>
      <S.Input
        {...field}
        {...props}
        errorMessage={error?.message}
        id={`${prefix}-${field.name}`}
        data-testid={`${prefix}-${field.name}`}
      >
        {label}
        {required && <S.RequiredAsterisk />}
      </S.Input>
    </S.ContainerOverride>
  );
};
