import { Text } from '@/components/Text/Text';
import {
  IconPerson as ChiliIconPerson,
  IconCalendar as ChiliIconCalendar,
  IconClock as ChiliIconClock,
  IconRestaurantTray as ChiliIconRestaurantTray,
  IconNotes as ChiliIconNotes,
} from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FOR_MOBILE_ONLY } from '@/utils/emotion-utils';
import { ComponentProps } from 'react';

export const MainContainer = styled(Flex)`
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.customization.colors.border.line};
  border-radius: 4px;
  padding: ${({ theme }) => theme.space.m};
  gap: ${({ theme }) => theme.space.m};
`;

export const FlexRowHeader = styled(Flex)`
  ${FOR_MOBILE_ONLY} {
    justify-content: space-between;
  }
  justify-content: space-evenly;
`;

export const HeaderInfo = styled(Flex)`
  flex-wrap: wrap;
  text-transform: capitalize;
  justify-content: center;
  gap: 12px;
`;

export const IconWrapper = styled.div`
  display: flex;
  vertical-align: top;
  width: 18px;
`;

const iconCss = css`
  height: 18px;
  width: 18px;
`;

export const IconPax = styled(ChiliIconPerson)`
  ${iconCss}
`;

export const IconDate = styled(ChiliIconCalendar)`
  ${iconCss}
`;

export const IconTime = styled(ChiliIconClock)`
  ${iconCss}
`;

export const IconMenu = styled(ChiliIconRestaurantTray)`
  ${iconCss}
`;

export const IconNotes = styled(ChiliIconNotes)`
  ${iconCss}
`;

export const TextBold = styled((props: ComponentProps<typeof Text>) => <Text as="span" {...props} />)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.m};
  margin: 0;
`;

export const TextM = styled((props: ComponentProps<typeof Text>) => <Text as="span" {...props} />)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  margin: 0;
`;

export const VerticalSeparator = styled.div`
  background-color: ${({ theme }) => theme.customization.colors.border.line};
  height: 18px;
  width: 1px;
`;

export const ContentFlex = styled(Flex)<{ centeredStyle?: boolean }>`
  gap: 12px;
  ${({ centeredStyle }) => centeredStyle && `justify-content: center`}
`;

export const ContentTextFlex = styled(Flex)`
  display: block;
  line-height: ${({ theme }) => theme.lineHeight.s};
`;

export const ContentTextFlexGap = styled(Flex)`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
