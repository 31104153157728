import styled from '@emotion/styled';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import { FOR_MOBILE_ONLY } from '@/utils/emotion-utils';
import { Button } from '@/components/Button/Button';

export const BackButtonContainer = styled(Box)`
  z-index: 1;
  grid-area: back;
  ${FOR_MOBILE_ONLY} {
    justify-self: flex-start;
    padding-left: ${({ theme }) => theme.space.xs};
  }
`;

export const BackButton = styled(Button)`
  svg {
    margin-right: ${({ theme }) => theme.space.xs};
  }
`;
