import { InputLabel as ChiliInputLabel } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/Input';
import { PhoneSelect as ChiliPhoneSelect } from './PhoneSelect';
import styled from '@emotion/styled';

export const InputLabel = styled(ChiliInputLabel)`
  padding-top: ${({ theme }) => theme.space.s};
  font-family: ${({ theme }) => theme.customization.fonts.body};
`;

export const Field = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.space.xxs};

  div + label {
    flex-grow: 1;
    margin-left: ${({ theme }) => theme.space.m};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const PhoneSelect = styled(ChiliPhoneSelect)`
  font-family: ${({ theme }) => theme.customization.fonts.body};

  & span {
    color: ${({ theme }) => theme.customization.colors.text.default};
  }

  & div {
    margin-top: 0px;
  }

  & > div > div {
    flex-direction: row;
  }

  & > div > span:first-child {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }

  label {
    padding-top: 0;
  }

  .disabled {
    color: ${({ theme }) => theme.colors.gray.m};
  }

  .inputLabel {
    display: none;
  }
`;

export { Input as MainInput, RequiredAsterisk } from '@/components/ReactHookForm/Input.styles';
