import React from 'react';
import styled from '@emotion/styled';
import { px2rem } from '@lafourchette/design-system-theming/utils';
import Text from '@lafourchette/react-chili/dist/cjs/components/Atoms/Text';
import { FOR_DESKTOP } from '@/utils/emotion-utils';

const CONTENT_WIDTH = px2rem(490);

export const LeavePaymentStepModalContent = styled.div`
  display: flex;
  flex-direction: column;
  ${FOR_DESKTOP} {
    width: ${CONTENT_WIDTH};
    padding-bottom: ${({ theme }) => theme.space.m};
  }
`;

export const LeavePaymentStepModalTitle = (props: React.HTMLAttributes<HTMLParagraphElement>) => {
  return <Text {...props} textAlign="center" variant="bodyRegular" my="m" />;
};

export const LeavePaymentStepModalText = (props: React.HTMLAttributes<HTMLParagraphElement>) => {
  return <Text {...props} textAlign="center" variant="bodyLight" mt="0" mb="xl" />;
};

export const LeavePaymentStepModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.space.m};
  margin-bottom: ${({ theme }) => theme.space.m};
  ${FOR_DESKTOP} {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.space.xs};
  }
`;
