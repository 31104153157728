import { FormattedDate, FormattedMessage, FormattedTime, IntlShape, useIntl } from 'react-intl';
import { dateTimeToDate } from '@/utils/date-utils';
import { Divider } from '@/components/Divider/Divider';
import { validate as isValidUUID } from 'uuid';
import * as S from './BookingSummary.styles';

export const getFieldKeyValueText = ({
  customFieldLabels,
  customFieldProperties,
  fieldKey,
  fieldValue,
  intl,
}: {
  customFieldLabels: Record<string, string>;
  customFieldProperties: Record<string, { title: string }>;
  fieldKey: string;
  fieldValue: string | number | boolean | null;
  intl: IntlShape;
}): { textKey: string | null; textValue: string | number | null } => {
  const textKey = customFieldLabels?.[customFieldProperties?.[fieldKey]?.title] || null;
  let textValue = null;
  switch (typeof fieldValue) {
    case 'string':
      textValue = isValidUUID(fieldValue) ? customFieldLabels?.[fieldValue] : fieldValue;
      break;
    case 'number':
      textValue = fieldValue;
      break;
    case 'boolean':
      textValue =
        fieldValue === true
          ? intl.formatMessage({ id: 'tf_widget_yes', defaultMessage: 'Yes' })
          : intl.formatMessage({ id: 'tf_widget_no', defaultMessage: 'No' });
      break;
    default:
      textValue = fieldValue;
  }
  return {
    textKey,
    textValue,
  };
};

export type BookingSummaryProps = {
  pax: number;
  date: string;
  hour: string;
  hasOffer: boolean;
  offerPrice?: number;
  offerCurrency?: string;
  offerName?: string;
  offerCenteredStyle?: boolean;
  area?: string;
  specialRequest?: string;
  customFieldValues?: Record<string, string | number | boolean | null>;
  customFieldLabels?: Record<string, string>;
  customFieldProperties?: Record<string, { title: string }>;
};

export const BookingSummary = ({
  pax,
  date,
  hour,
  hasOffer,
  offerPrice,
  offerCurrency,
  offerName,
  offerCenteredStyle,
  area,
  specialRequest,
  customFieldLabels = {},
  customFieldProperties = {},
  customFieldValues = {},
}: BookingSummaryProps) => {
  const intl = useIntl();
  const hasAdditionalInfo = area || specialRequest || Object.keys(customFieldValues).length > 0;

  return (
    <S.MainContainer>
      <S.FlexRowHeader>
        <S.HeaderInfo>
          <S.IconPax />
          <S.TextBold data-testid="booking-summary-pax">
            <FormattedMessage
              id="tf_widget_booking_paxs_abbrv"
              defaultMessage="{nPartySize, plural, one{one pers} other{# pers}}"
              values={{ nPartySize: pax }}
            />
          </S.TextBold>
        </S.HeaderInfo>
        <S.VerticalSeparator />
        <S.HeaderInfo>
          <S.IconDate />
          <S.TextBold data-testid="booking-summary-date">
            <FormattedDate value={`${date}T00:00:00`} weekday="short" month="short" day="2-digit" />
          </S.TextBold>
        </S.HeaderInfo>
        <S.VerticalSeparator />
        <S.HeaderInfo>
          <S.IconTime />
          <S.TextBold data-testid="booking-summary-hour">
            <FormattedTime value={dateTimeToDate(`${date} ${hour}`, 'yyyy-MM-dd HH:mm')} />
          </S.TextBold>
        </S.HeaderInfo>
      </S.FlexRowHeader>
      {hasOffer && (
        <>
          <Divider />
          <S.ContentFlex data-testid="booking-summary-menu-info" centeredStyle={offerCenteredStyle}>
            <S.IconWrapper>
              <S.IconMenu />
            </S.IconWrapper>
            <S.ContentTextFlex>
              <S.TextBold>
                {`${offerName}`}
                {offerPrice != null &&
                  offerCurrency &&
                  ` (${intl.formatNumber(offerPrice, { style: 'currency', currency: offerCurrency })})`}
              </S.TextBold>
            </S.ContentTextFlex>
          </S.ContentFlex>
        </>
      )}
      {hasAdditionalInfo && (
        <>
          <Divider />
          <S.ContentFlex>
            <S.IconWrapper>
              <S.IconNotes />
            </S.IconWrapper>
            <S.ContentTextFlexGap>
              <S.TextBold>
                <FormattedMessage
                  id="tf_widget_booking_summary_additional_information"
                  defaultMessage="Additional information"
                />
              </S.TextBold>
              {Object.entries(customFieldValues).map(([fieldKey, fieldValue]) => {
                const { textKey, textValue } = getFieldKeyValueText({
                  customFieldLabels,
                  customFieldProperties,
                  fieldKey,
                  fieldValue,
                  intl,
                });
                if (!textKey || textValue == null) {
                  return null;
                }
                return (
                  <S.ContentTextFlex key={fieldKey}>
                    <S.TextBold>
                      <FormattedMessage
                        id="tf_widget_booking_summary_text_colon"
                        defaultMessage="{text}:"
                        values={{ text: textKey }}
                      />
                    </S.TextBold>
                    <S.TextM>{` ${textValue}`}</S.TextM>
                  </S.ContentTextFlex>
                );
              })}
              {area && (
                <S.ContentTextFlex>
                  <S.TextBold>
                    <FormattedMessage
                      data-testid="booking-summary-area"
                      id="tf_widget_booking_summary_area_v2"
                      defaultMessage="Area:"
                    />
                  </S.TextBold>
                  <S.TextM>{` ${area}`}</S.TextM>
                </S.ContentTextFlex>
              )}
              {specialRequest && (
                <S.ContentTextFlex>
                  <S.TextBold>
                    <FormattedMessage
                      id="tf_widget_booking_summary_special_request_v2"
                      defaultMessage="Special request:"
                    />
                  </S.TextBold>
                  <S.TextM>{` ${specialRequest}`}</S.TextM>
                </S.ContentTextFlex>
              )}
            </S.ContentTextFlexGap>
          </S.ContentFlex>
        </>
      )}
    </S.MainContainer>
  );
};
