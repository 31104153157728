import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { PaymentConfig, PaymentData, PaymentGuaranteeIntent } from '@/types/payment';
import { getStripePublishableKey } from '@/utils/payment-utils';

export type PaymentDataContext = PaymentData & {
  setupConfig: (newConfig: PaymentConfig) => void;
  updateAuthData: (newPaymentGuaranteeIntent: PaymentGuaranteeIntent) => void;
};

const PaymentContext = createContext<PaymentDataContext>({
  needsPayment: false,
  setupConfig: () => {},
  updateAuthData: () => {},
});

export const usePaymentContext = () => {
  return useContext(PaymentContext);
};

export const PaymentContextProvider: React.FC<PropsWithChildren & { data: PaymentData }> = ({
  data: { config: paymentConfig, needsPayment },
  ...props
}) => {
  const [config, setConfig] = useState<PaymentConfig | undefined>(paymentConfig);

  useEffect(() => {
    if (paymentConfig) {
      setConfig(paymentConfig);
    }
  }, [paymentConfig]);

  const setupConfig = (newConfig: PaymentConfig) => {
    setConfig(newConfig);
  };

  const updateAuthData = (paymentGuaranteeIntent: PaymentGuaranteeIntent) => {
    const stripeAuth = {
      paymentGuaranteeIntent: { ...paymentGuaranteeIntent },
      publishableKey: getStripePublishableKey(paymentGuaranteeIntent.stripeAuthData?.stripePublishableKeyIdentifier),
    };
    setConfig((prevConfig) => {
      if (!prevConfig) {
        return prevConfig;
      }
      return {
        ...prevConfig,
        paymentProvider: {
          ...prevConfig.paymentProvider,
          authData: stripeAuth,
        },
      };
    });
  };

  return <PaymentContext.Provider {...props} value={{ needsPayment, config, setupConfig, updateAuthData }} />;
};
