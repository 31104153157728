import { FOR_TABLET_AND_MORE } from '@/utils/emotion-utils';
import styled from '@emotion/styled';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import ChiliRequiredAsterisk from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/RequiredAsterisk';

export const RequiredLegend = styled(Flex)`
  align-items: center;
  color: ${({ theme }) => theme.customization.colors.text.default};
  font-family: ${({ theme }) => theme.customization.fonts.body};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 15px;
  ${FOR_TABLET_AND_MORE} {
    margin-top: ${({ theme }) => theme.space.l};
  }
  margin-top: ${({ theme }) => theme.space.m};
  margin-bottom: ${({ theme }) => theme.space.s};
`;

export const RequiredAsterisk = styled(ChiliRequiredAsterisk)`
  color: ${({ theme }) => theme.customization.colors.text.default};
  padding-left: 0;
  font-size: ${({ theme }) => theme.fontSizes.xxl};
`;
