import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ContactInformation } from '@/components/ContactForm/ContactForm';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@apollo/client';
import { CreateOrUpdateOfflineCustomerDocument } from '@/graphql/client-backend.generated';
import { useBooking } from '@/hooks/useBooking';
import { useError } from '@/contexts/ErrorProvider/ErrorProvider';
import { GetFormInfoDocument } from '@/graphql/types.generated';
import { getSchema } from '@/components/ContactForm/ContactForm.schema';

export function useContactForm({
  onSubmitSuccess,
}: {
  onSubmitSuccess: (contactInformation: ContactInformation & { customerUuid: string }) => void;
}) {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    bookingParams: { pax, time, date, couponCode, isWaitingList },
    restaurantUuid,
  } = useBooking();

  const { setErrorMessage, setOnAction, closeError } = useError();

  if (!pax || !time || !date || !restaurantUuid) {
    throw new Error(
      `Required context not provided : pax:${pax}, time:${time}, date:${date}, restaurantUuid:${restaurantUuid}`,
    );
  }

  const {
    data: formInfoData,
    loading: isFormInfoLoading,
    error: formInfoError,
  } = useQuery(GetFormInfoDocument, {
    variables: {
      restaurantUuid,
      date,
      timeslot: Number(time),
      partySize: pax,
      offerUuid: '', // needed because graphql schema requires it even when we skip it (using skipOfferQuery)
      voucherCode: couponCode ?? '',
      skipOfferQuery: true,
      skipCustomFieldQuery: true,
      skipVoucherQuery: !couponCode,
    },
  });

  const mealDuration = formInfoData?.seatingTime?.mealDuration;
  const mealStart = Number(time);

  if (formInfoError) {
    throw new Error(formInfoError?.message);
  }

  const [createOrUpdateOfflineCustomer] = useMutation(CreateOrUpdateOfflineCustomerDocument);

  const submitContactForm = (contactInformation: ContactInformation) => {
    closeError();
    setIsSubmitting(true);
    createOrUpdateOfflineCustomer({
      variables: {
        input: {
          civility: contactInformation.civility,
          firstName: contactInformation.firstName,
          lastName: contactInformation.lastName,
          email: contactInformation.email,
          phoneNumber: contactInformation.phone,
          restaurantUuid,
          restaurantNewsletterOptIn: contactInformation.restaurantNewsletterOptIn,
        },
      },
      onError: () => {
        setOnAction(() => handleSubmit(submitContactForm));
        setErrorMessage(
          intl.formatMessage({
            id: 'tf_widget_submit_error_message',
            defaultMessage: `Something went wrong, please try again.`,
          }),
        );
        setIsSubmitting(false);
      },
      onCompleted: (data) => {
        onSubmitSuccess({ ...contactInformation, customerUuid: data.createOrUpdateOfflineCustomer.customerUuid });
        setIsSubmitting(false);
      },
    });
  };

  const { formState, control, handleSubmit, watch, setValue } = useForm<ContactInformation>({
    resolver: zodResolver(getSchema(intl)),
    mode: 'onChange',
  });

  const civilitySelector = watch('civilitySelector');
  const customCivility = watch('customCivility');

  useEffect(() => {
    const civilityValue = civilitySelector === 'custom' ? customCivility : civilitySelector;
    setValue('civility', civilityValue);
  }, [civilitySelector, customCivility, setValue]);

  return {
    pax,
    isWaitingList,
    isLoading: isFormInfoLoading,
    mealStart,
    voucherData: formInfoData?.voucher,
    mealDuration,
    civilitySelector,
    customCivility,
    formState,
    control,
    isSubmitting,
    setValue,
    handleSubmit,
    submitContactForm,
  };
}
