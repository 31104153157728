import { StepStatus } from '@/components/WizardLayout/UserCheckoutStepper/Step/Step';

export const getStatus = (currentStep: number, stepNumber: number): StepStatus => {
  if (currentStep === stepNumber) {
    return 'active';
  }

  if (currentStep > stepNumber) {
    return 'completed';
  }

  return 'disabled';
};

export const getPercentage = (currentStep: number, stepNumber: number) => {
  return currentStep > stepNumber ? 100 : 0;
};
