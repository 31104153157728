import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import ChiliCheckbox from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/Checkbox';
import { ComponentProps } from 'react';
import styled from '@emotion/styled';

export interface ChiliFormFieldProps {
  prefix: string;
}

type ChiliInputProps = ChiliFormFieldProps & {
  label: React.ReactNode;
};

export type CheckboxProps<T extends FieldValues> = UseControllerProps<T> &
  ComponentProps<typeof ChiliCheckbox> &
  ChiliInputProps;

const StyledCheckbox = styled(ChiliCheckbox)<{ disabled?: boolean }>`
  & input + .checkboxFake {
    background-color: ${({ theme }) => theme.customization.colors.checkbox.background.unchecked} !important;
    border-color: ${({ theme }) => theme.customization.colors.checkbox.border.default} !important;
    transition: border-color ${({ theme }) => theme.duration.m};
  }

  & .checkboxLabel {
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.customization.colors.checkbox.border.disabled
        : theme.customization.colors.checkbox.border.default} !important;
    font-family: ${({ theme }) => theme.customization.fonts.body};
  }

  & svg {
    color: ${({ theme }) => theme.customization.colors.checkbox.svg};
  }

  & input:checked + .checkboxFake {
    background-color: ${({ theme }) => theme.customization.colors.checkbox.background.checked} !important;
    border-color: ${({ theme }) => theme.customization.colors.checkbox.border.checked} !important;
  }

  & input:not(:disabled):not(:checked):hover + .checkboxFake {
    border-color: ${({ theme }) => theme.customization.colors.checkbox.border.hover} !important;
    transition: border-color 0s;
  }

  & input:disabled + .checkboxFake {
    border-color: ${({ theme }) => theme.customization.colors.checkbox.border.disabled} !important;
  }
`;

export const Checkbox = <T extends Record<string, unknown>>({ label, prefix, ...props }: CheckboxProps<T>) => {
  const {
    field: { value, onChange, onBlur, name },
  } = useController(props);

  return (
    <StyledCheckbox onChange={onChange} onBlur={onBlur} {...props} checked={Boolean(value)} id={`${prefix}-${name}`}>
      {label}
    </StyledCheckbox>
  );
};
