import * as S from '@/components/WizardLayout/Subtitle/WizardSubtitle.styles';
import { WizardStep } from '@/types/WizardBooking';
import { useBooking } from '@/hooks/useBooking';
import { FormattedMessage } from 'react-intl';

export const WizardSubtitle: React.FC = () => {
  const {
    step,
    bookingParams: { isWaitingList },
  } = useBooking();

  switch (step) {
    case WizardStep.Pax:
      return (
        <S.TitleBox>
          <S.Title variant="title2">
            <S.IconPersons />
            <FormattedMessage id="tf_widget_wizard_title_pax_v2" defaultMessage="How many guests?" />
          </S.Title>
        </S.TitleBox>
      );

    case WizardStep.Date:
      return (
        <S.TitleBox>
          <S.Title variant="title2">
            <S.IconCalendar />
            <FormattedMessage id="tf_widget_wizard_title_date" defaultMessage="For what day?" />
          </S.Title>
        </S.TitleBox>
      );

    case WizardStep.Hour:
      return (
        <S.TitleBox>
          <S.Title variant="title2">
            <S.IconClock />
            <FormattedMessage id="tf_widget_wizard_title_hour" defaultMessage="At what hour?" />
          </S.Title>
        </S.TitleBox>
      );

    case WizardStep.Offer:
      if (isWaitingList) {
        return (
          <S.TitleBox>
            <S.Title variant="title2">
              <FormattedMessage
                id="tf_widget_wizard_title_offer_waiting_list"
                defaultMessage="Offers and experiences"
              />
            </S.Title>
            <S.TitleContext>
              <FormattedMessage
                id="tf_widget_wizard_subtitle_offer_waiting_list"
                defaultMessage="Join the waiting list for an offer or experience below and complete your reservation."
              />
            </S.TitleContext>
          </S.TitleBox>
        );
      }

      return (
        <S.TitleBox>
          <S.Title variant="title2">
            <S.IconRestaurantTray />
            <FormattedMessage id="tf_widget_wizard_title_offer" defaultMessage="Offers and experiences" />
          </S.Title>
        </S.TitleBox>
      );

    case WizardStep.UserInformation:
      return (
        <S.HeaderTitleBox>
          <S.Title variant="title2">
            <FormattedMessage id="tf_widget_wizard_title_contact" defaultMessage="Reservation details" />
          </S.Title>
        </S.HeaderTitleBox>
      );

    case WizardStep.MoreInfo:
      return (
        <S.HeaderTitleBox>
          <S.Title variant="title2">
            <FormattedMessage id="tf_widget_wizard_title_moreInfo" defaultMessage="Additional information" />
          </S.Title>
        </S.HeaderTitleBox>
      );

    case WizardStep.Payment:
      return (
        <S.HeaderTitleBox>
          <S.Title variant="title2">
            <FormattedMessage id="tf_widget_wizard_title_creditCardGarantee" defaultMessage="Credit card details" />
          </S.Title>
        </S.HeaderTitleBox>
      );

    default:
      return null;
  }
};
