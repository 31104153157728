import { CountryCode, getCountries, getCountryCallingCode, getExampleNumber } from 'libphonenumber-js/max';
import examples from 'libphonenumber-js/mobile/examples';
import { IntlShape } from 'react-intl';
import { Option } from './PhoneCountryCodeSelect.types';

/* 
  based on
  https://github.com/lafourchette/tfm-front/blob/master/src/domains/constants.ts 
  AC, XK, TA are ignored
*/

type TranslatedCountries = Exclude<CountryCode, 'AC' | 'TA' | 'XK'>;
type TranslatedCountryName = Record<TranslatedCountries, string>;

function isInCountryNames(countryName: CountryCode, countries: TranslatedCountryName): boolean {
  return !!countries[countryName as keyof typeof countries];
}

export function getExamplePhoneNumber(countryCode: CountryCode) {
  return getExampleNumber(countryCode, examples);
}

export function getCountriesOptions(intl: IntlShape) {
  const countries = getL10nCountries(intl);
  return getCountries()
    .reduce<Option[]>((options, countryCode) => {
      if (!isInCountryNames(countryCode, countries)) {
        return options;
      }
      const countryCallingCode = getCountryCallingCode(countryCode);
      return [
        ...options,
        {
          label: countries[countryCode as keyof typeof countries],
          value: {
            countryCode,
            countryCallingCode,
          },
        },
      ];
    }, [])
    .sort((optionA: Option, optionB: Option) => {
      return optionA.label.localeCompare(optionB.label);
    });
}

function getL10nCountries(intl: IntlShape): TranslatedCountryName {
  return {
    AD: intl.formatMessage({ id: 'tf_widget_country_ad', defaultMessage: 'Andorra' }),
    AE: intl.formatMessage({ id: 'tf_widget_country_ae', defaultMessage: 'United Arab Emirates' }),
    AF: intl.formatMessage({ id: 'tf_widget_country_af', defaultMessage: 'Afghanistan' }),
    AG: intl.formatMessage({ id: 'tf_widget_country_ag', defaultMessage: 'Antigua and Barbuda' }),
    AI: intl.formatMessage({ id: 'tf_widget_country_ai', defaultMessage: 'Anguilla' }),
    AL: intl.formatMessage({ id: 'tf_widget_country_al', defaultMessage: 'Albania' }),
    AM: intl.formatMessage({ id: 'tf_widget_country_am', defaultMessage: 'Armenia' }),
    AO: intl.formatMessage({ id: 'tf_widget_country_ao', defaultMessage: 'Angola' }),
    AR: intl.formatMessage({ id: 'tf_widget_country_ar', defaultMessage: 'Argentina' }),
    AS: intl.formatMessage({ id: 'tf_widget_country_as', defaultMessage: 'American Samoa' }),
    AT: intl.formatMessage({ id: 'tf_widget_country_at', defaultMessage: 'Austria' }),
    AU: intl.formatMessage({ id: 'tf_widget_country_au', defaultMessage: 'Australia' }),
    AW: intl.formatMessage({ id: 'tf_widget_country_aw', defaultMessage: 'Aruba' }),
    AX: intl.formatMessage({ id: 'tf_widget_country_ax', defaultMessage: 'Åland Islands' }),
    AZ: intl.formatMessage({ id: 'tf_widget_country_az', defaultMessage: 'Azerbaijan' }),
    BA: intl.formatMessage({ id: 'tf_widget_country_ba', defaultMessage: 'Bosnia and Herzegovina' }),
    BB: intl.formatMessage({ id: 'tf_widget_country_bb', defaultMessage: 'Barbados' }),
    BD: intl.formatMessage({ id: 'tf_widget_country_bd', defaultMessage: 'Bangladesh' }),
    BE: intl.formatMessage({ id: 'tf_widget_country_be', defaultMessage: 'Belgium' }),
    BF: intl.formatMessage({ id: 'tf_widget_country_bf', defaultMessage: 'Burkina Faso' }),
    BG: intl.formatMessage({ id: 'tf_widget_country_bg', defaultMessage: 'Bulgaria' }),
    BH: intl.formatMessage({ id: 'tf_widget_country_bh', defaultMessage: 'Bahrain' }),
    BI: intl.formatMessage({ id: 'tf_widget_country_bi', defaultMessage: 'Burundi' }),
    BJ: intl.formatMessage({ id: 'tf_widget_country_bj', defaultMessage: 'Benin' }),
    BL: intl.formatMessage({ id: 'tf_widget_country_bl', defaultMessage: 'Saint Barthélemy' }),
    BM: intl.formatMessage({ id: 'tf_widget_country_bm', defaultMessage: 'Bermuda' }),
    BN: intl.formatMessage({ id: 'tf_widget_country_bn', defaultMessage: 'Brunei Darussalam' }),
    BO: intl.formatMessage({ id: 'tf_widget_country_bo', defaultMessage: 'Bolivia' }),
    BQ: intl.formatMessage({ id: 'tf_widget_country_bq', defaultMessage: 'Bonaire, Sint Eustatius and Saba' }),
    BR: intl.formatMessage({ id: 'tf_widget_country_br', defaultMessage: 'Brazil' }),
    BS: intl.formatMessage({ id: 'tf_widget_country_bs', defaultMessage: 'Bahamas' }),
    BT: intl.formatMessage({ id: 'tf_widget_country_bt', defaultMessage: 'Bhutan' }),
    BW: intl.formatMessage({ id: 'tf_widget_country_bw', defaultMessage: 'Botswana' }),
    BY: intl.formatMessage({ id: 'tf_widget_country_by', defaultMessage: 'Belarus' }),
    BZ: intl.formatMessage({ id: 'tf_widget_country_bz', defaultMessage: 'Belize' }),
    CA: intl.formatMessage({ id: 'tf_widget_country_ca', defaultMessage: 'Canada' }),
    CC: intl.formatMessage({ id: 'tf_widget_country_cc', defaultMessage: 'Cocos (Keeling) Islands' }),
    CD: intl.formatMessage({ id: 'tf_widget_country_cd', defaultMessage: 'Congo, The Democratic Republic Of The' }),
    CF: intl.formatMessage({ id: 'tf_widget_country_cf', defaultMessage: 'Central African Republic' }),
    CG: intl.formatMessage({ id: 'tf_widget_country_cg', defaultMessage: 'Congo' }),
    CH: intl.formatMessage({ id: 'tf_widget_country_ch', defaultMessage: 'Switzerland' }),
    CI: intl.formatMessage({ id: 'tf_widget_country_ci', defaultMessage: "Côte D'Ivoire" }),
    CK: intl.formatMessage({ id: 'tf_widget_country_ck', defaultMessage: 'Cook Islands' }),
    CL: intl.formatMessage({ id: 'tf_widget_country_cl', defaultMessage: 'Chile' }),
    CM: intl.formatMessage({ id: 'tf_widget_country_cm', defaultMessage: 'Cameroon' }),
    CN: intl.formatMessage({ id: 'tf_widget_country_cn', defaultMessage: 'China' }),
    CO: intl.formatMessage({ id: 'tf_widget_country_co', defaultMessage: 'Colombia' }),
    CR: intl.formatMessage({ id: 'tf_widget_country_cr', defaultMessage: 'Costa Rica' }),
    CU: intl.formatMessage({ id: 'tf_widget_country_cu', defaultMessage: 'Cuba' }),
    CV: intl.formatMessage({ id: 'tf_widget_country_cv', defaultMessage: 'Cape Verde' }),
    CW: intl.formatMessage({ id: 'tf_widget_country_cw', defaultMessage: 'Curaçao' }),
    CX: intl.formatMessage({ id: 'tf_widget_country_cx', defaultMessage: 'Christmas Island' }),
    CY: intl.formatMessage({ id: 'tf_widget_country_cy', defaultMessage: 'Cyprus' }),
    CZ: intl.formatMessage({ id: 'tf_widget_country_cz', defaultMessage: 'Czech Republic' }),
    DE: intl.formatMessage({ id: 'tf_widget_country_de', defaultMessage: 'Germany' }),
    DJ: intl.formatMessage({ id: 'tf_widget_country_dj', defaultMessage: 'Djibouti' }),
    DK: intl.formatMessage({ id: 'tf_widget_country_dk', defaultMessage: 'Denmark' }),
    DM: intl.formatMessage({ id: 'tf_widget_country_dm', defaultMessage: 'Dominica' }),
    DO: intl.formatMessage({ id: 'tf_widget_country_do', defaultMessage: 'Dominican Republic' }),
    DZ: intl.formatMessage({ id: 'tf_widget_country_dz', defaultMessage: 'Algeria' }),
    EC: intl.formatMessage({ id: 'tf_widget_country_ec', defaultMessage: 'Ecuador' }),
    EE: intl.formatMessage({ id: 'tf_widget_country_ee', defaultMessage: 'Estonia' }),
    EG: intl.formatMessage({ id: 'tf_widget_country_eg', defaultMessage: 'Egypt' }),
    EH: intl.formatMessage({ id: 'tf_widget_country_eh', defaultMessage: 'Western Sahara' }),
    ER: intl.formatMessage({ id: 'tf_widget_country_er', defaultMessage: 'Eritrea' }),
    ES: intl.formatMessage({ id: 'tf_widget_country_es', defaultMessage: 'Spain' }),
    ET: intl.formatMessage({ id: 'tf_widget_country_et', defaultMessage: 'Ethiopia' }),
    FI: intl.formatMessage({ id: 'tf_widget_country_fi', defaultMessage: 'Finland' }),
    FJ: intl.formatMessage({ id: 'tf_widget_country_fj', defaultMessage: 'Fiji' }),
    FK: intl.formatMessage({ id: 'tf_widget_country_fk', defaultMessage: 'Falkland Islands (Malvinas)' }),
    FM: intl.formatMessage({ id: 'tf_widget_country_fm', defaultMessage: 'Micronesia, Federated States Of' }),
    FO: intl.formatMessage({ id: 'tf_widget_country_fo', defaultMessage: 'Faroe Islands' }),
    FR: intl.formatMessage({ id: 'tf_widget_country_fr', defaultMessage: 'France' }),
    GA: intl.formatMessage({ id: 'tf_widget_country_ga', defaultMessage: 'Gabon' }),
    GB: intl.formatMessage({ id: 'tf_widget_country_gb', defaultMessage: 'United Kingdom' }),
    GD: intl.formatMessage({ id: 'tf_widget_country_gd', defaultMessage: 'Grenada' }),
    GE: intl.formatMessage({ id: 'tf_widget_country_ge', defaultMessage: 'Georgia' }),
    GF: intl.formatMessage({ id: 'tf_widget_country_gf', defaultMessage: 'French Guiana' }),
    GG: intl.formatMessage({ id: 'tf_widget_country_gg', defaultMessage: 'Guernsey' }),
    GH: intl.formatMessage({ id: 'tf_widget_country_gh', defaultMessage: 'Ghana' }),
    GI: intl.formatMessage({ id: 'tf_widget_country_gi', defaultMessage: 'Gibraltar' }),
    GL: intl.formatMessage({ id: 'tf_widget_country_gl', defaultMessage: 'Greenland' }),
    GM: intl.formatMessage({ id: 'tf_widget_country_gm', defaultMessage: 'Gambia' }),
    GN: intl.formatMessage({ id: 'tf_widget_country_gn', defaultMessage: 'Guinea' }),
    GP: intl.formatMessage({ id: 'tf_widget_country_gp', defaultMessage: 'Guadeloupe' }),
    GQ: intl.formatMessage({ id: 'tf_widget_country_gq', defaultMessage: 'Equatorial Guinea' }),
    GR: intl.formatMessage({ id: 'tf_widget_country_gr', defaultMessage: 'Greece' }),
    GT: intl.formatMessage({ id: 'tf_widget_country_gt', defaultMessage: 'Guatemala' }),
    GU: intl.formatMessage({ id: 'tf_widget_country_gu', defaultMessage: 'Guam' }),
    GW: intl.formatMessage({ id: 'tf_widget_country_gw', defaultMessage: 'Guinea-Bissau' }),
    GY: intl.formatMessage({ id: 'tf_widget_country_gy', defaultMessage: 'Guyana' }),
    HK: intl.formatMessage({ id: 'tf_widget_country_hk', defaultMessage: 'Hong Kong' }),
    HN: intl.formatMessage({ id: 'tf_widget_country_hn', defaultMessage: 'Honduras' }),
    HR: intl.formatMessage({ id: 'tf_widget_country_hr', defaultMessage: 'Croatia' }),
    HT: intl.formatMessage({ id: 'tf_widget_country_ht', defaultMessage: 'Haiti' }),
    HU: intl.formatMessage({ id: 'tf_widget_country_hu', defaultMessage: 'Hungary' }),
    ID: intl.formatMessage({ id: 'tf_widget_country_id', defaultMessage: 'Indonesia' }),
    IE: intl.formatMessage({ id: 'tf_widget_country_ie', defaultMessage: 'Ireland' }),
    IL: intl.formatMessage({ id: 'tf_widget_country_il', defaultMessage: 'Israel' }),
    IM: intl.formatMessage({ id: 'tf_widget_country_im', defaultMessage: 'Isle of Man' }),
    IN: intl.formatMessage({ id: 'tf_widget_country_in', defaultMessage: 'India' }),
    IO: intl.formatMessage({ id: 'tf_widget_country_io', defaultMessage: 'British Indian Ocean Territory' }),
    IQ: intl.formatMessage({ id: 'tf_widget_country_iq', defaultMessage: 'Iraq' }),
    IR: intl.formatMessage({ id: 'tf_widget_country_ir', defaultMessage: 'Iran, Islamic Republic Of' }),
    IS: intl.formatMessage({ id: 'tf_widget_country_is', defaultMessage: 'Iceland' }),
    IT: intl.formatMessage({ id: 'tf_widget_country_it', defaultMessage: 'Italy' }),
    JE: intl.formatMessage({ id: 'tf_widget_country_je', defaultMessage: 'Jersey' }),
    JM: intl.formatMessage({ id: 'tf_widget_country_jm', defaultMessage: 'Jamaica' }),
    JO: intl.formatMessage({ id: 'tf_widget_country_jo', defaultMessage: 'Jordan' }),
    JP: intl.formatMessage({ id: 'tf_widget_country_jp', defaultMessage: 'Japan' }),
    KE: intl.formatMessage({ id: 'tf_widget_country_ke', defaultMessage: 'Kenya' }),
    KG: intl.formatMessage({ id: 'tf_widget_country_kg', defaultMessage: 'Kyrgyzstan' }),
    KH: intl.formatMessage({ id: 'tf_widget_country_kh', defaultMessage: 'Cambodia' }),
    KI: intl.formatMessage({ id: 'tf_widget_country_ki', defaultMessage: 'Kiribati' }),
    KM: intl.formatMessage({ id: 'tf_widget_country_km', defaultMessage: 'Comoros' }),
    KN: intl.formatMessage({ id: 'tf_widget_country_kn', defaultMessage: 'Saint Kitts And Nevis' }),
    KP: intl.formatMessage({ id: 'tf_widget_country_kp', defaultMessage: "Korea, Democratic People's Republic Of" }),
    KR: intl.formatMessage({ id: 'tf_widget_country_kr', defaultMessage: 'Korea, Republic of' }),
    KW: intl.formatMessage({ id: 'tf_widget_country_kw', defaultMessage: 'Kuwait' }),
    KY: intl.formatMessage({ id: 'tf_widget_country_ky', defaultMessage: 'Cayman Islands' }),
    KZ: intl.formatMessage({ id: 'tf_widget_country_kz', defaultMessage: 'Kazakhstan' }),
    LA: intl.formatMessage({ id: 'tf_widget_country_la', defaultMessage: "Lao People's Democratic Republic" }),
    LB: intl.formatMessage({ id: 'tf_widget_country_lb', defaultMessage: 'Lebanon' }),
    LC: intl.formatMessage({ id: 'tf_widget_country_lc', defaultMessage: 'Saint Lucia' }),
    LI: intl.formatMessage({ id: 'tf_widget_country_li', defaultMessage: 'Liechtenstein' }),
    LK: intl.formatMessage({ id: 'tf_widget_country_lk', defaultMessage: 'Sri Lanka' }),
    LR: intl.formatMessage({ id: 'tf_widget_country_lr', defaultMessage: 'Liberia' }),
    LS: intl.formatMessage({ id: 'tf_widget_country_ls', defaultMessage: 'Lesotho' }),
    LT: intl.formatMessage({ id: 'tf_widget_country_lt', defaultMessage: 'Lithuania' }),
    LU: intl.formatMessage({ id: 'tf_widget_country_lu', defaultMessage: 'Luxembourg' }),
    LV: intl.formatMessage({ id: 'tf_widget_country_lv', defaultMessage: 'Latvia' }),
    LY: intl.formatMessage({ id: 'tf_widget_country_ly', defaultMessage: 'Libya' }),
    MA: intl.formatMessage({ id: 'tf_widget_country_ma', defaultMessage: 'Morocco' }),
    MC: intl.formatMessage({ id: 'tf_widget_country_mc', defaultMessage: 'Monaco' }),
    MD: intl.formatMessage({ id: 'tf_widget_country_md', defaultMessage: 'Moldova, Republic of' }),
    ME: intl.formatMessage({ id: 'tf_widget_country_me', defaultMessage: 'Montenegro' }),
    MF: intl.formatMessage({ id: 'tf_widget_country_mf', defaultMessage: 'Saint Martin' }),
    MG: intl.formatMessage({ id: 'tf_widget_country_mg', defaultMessage: 'Madagascar' }),
    MH: intl.formatMessage({ id: 'tf_widget_country_mh', defaultMessage: 'Marshall Islands' }),
    MK: intl.formatMessage({
      id: 'tf_widget_country_mk',
      defaultMessage: 'Macedonia, the Former Yugoslav Republic Of',
    }),
    ML: intl.formatMessage({ id: 'tf_widget_country_ml', defaultMessage: 'Mali' }),
    MM: intl.formatMessage({ id: 'tf_widget_country_mm', defaultMessage: 'Myanmar' }),
    MN: intl.formatMessage({ id: 'tf_widget_country_mn', defaultMessage: 'Mongolia' }),
    MO: intl.formatMessage({ id: 'tf_widget_country_mo', defaultMessage: 'Macao' }),
    MP: intl.formatMessage({ id: 'tf_widget_country_mp', defaultMessage: 'Northern Mariana Islands' }),
    MQ: intl.formatMessage({ id: 'tf_widget_country_mq', defaultMessage: 'Martinique' }),
    MR: intl.formatMessage({ id: 'tf_widget_country_mr', defaultMessage: 'Mauritania' }),
    MS: intl.formatMessage({ id: 'tf_widget_country_ms', defaultMessage: 'Montserrat' }),
    MT: intl.formatMessage({ id: 'tf_widget_country_mt', defaultMessage: 'Malta' }),
    MU: intl.formatMessage({ id: 'tf_widget_country_mu', defaultMessage: 'Mauritius' }),
    MV: intl.formatMessage({ id: 'tf_widget_country_mv', defaultMessage: 'Maldives' }),
    MW: intl.formatMessage({ id: 'tf_widget_country_mw', defaultMessage: 'Malawi' }),
    MX: intl.formatMessage({ id: 'tf_widget_country_mx', defaultMessage: 'Mexico' }),
    MY: intl.formatMessage({ id: 'tf_widget_country_my', defaultMessage: 'Malaysia' }),
    MZ: intl.formatMessage({ id: 'tf_widget_country_mz', defaultMessage: 'Mozambique' }),
    NA: intl.formatMessage({ id: 'tf_widget_country_na', defaultMessage: 'Namibia' }),
    NC: intl.formatMessage({ id: 'tf_widget_country_nc', defaultMessage: 'New Caledonia' }),
    NE: intl.formatMessage({ id: 'tf_widget_country_ne', defaultMessage: 'Niger' }),
    NF: intl.formatMessage({ id: 'tf_widget_country_nf', defaultMessage: 'Norfolk Island' }),
    NG: intl.formatMessage({ id: 'tf_widget_country_ng', defaultMessage: 'Nigeria' }),
    NI: intl.formatMessage({ id: 'tf_widget_country_ni', defaultMessage: 'Nicaragua' }),
    NL: intl.formatMessage({ id: 'tf_widget_country_nl', defaultMessage: 'Netherlands' }),
    NO: intl.formatMessage({ id: 'tf_widget_country_no', defaultMessage: 'Norway' }),
    NP: intl.formatMessage({ id: 'tf_widget_country_np', defaultMessage: 'Nepal' }),
    NR: intl.formatMessage({ id: 'tf_widget_country_nr', defaultMessage: 'Nauru' }),
    NU: intl.formatMessage({ id: 'tf_widget_country_nu', defaultMessage: 'Niue' }),
    NZ: intl.formatMessage({ id: 'tf_widget_country_nz', defaultMessage: 'New Zealand' }),
    OM: intl.formatMessage({ id: 'tf_widget_country_om', defaultMessage: 'Oman' }),
    PA: intl.formatMessage({ id: 'tf_widget_country_pa', defaultMessage: 'Panama' }),
    PE: intl.formatMessage({ id: 'tf_widget_country_pe', defaultMessage: 'Peru' }),
    PF: intl.formatMessage({ id: 'tf_widget_country_pf', defaultMessage: 'French Polynesia' }),
    PG: intl.formatMessage({ id: 'tf_widget_country_pg', defaultMessage: 'Papua New Guinea' }),
    PH: intl.formatMessage({ id: 'tf_widget_country_ph', defaultMessage: 'Philippines' }),
    PK: intl.formatMessage({ id: 'tf_widget_country_pk', defaultMessage: 'Pakistan' }),
    PL: intl.formatMessage({ id: 'tf_widget_country_pl', defaultMessage: 'Poland' }),
    PM: intl.formatMessage({ id: 'tf_widget_country_pm', defaultMessage: 'Saint Pierre And Miquelon' }),
    PR: intl.formatMessage({ id: 'tf_widget_country_pr', defaultMessage: 'Puerto Rico' }),
    PS: intl.formatMessage({ id: 'tf_widget_country_ps', defaultMessage: 'Palestine, State of' }),
    PT: intl.formatMessage({ id: 'tf_widget_country_pt', defaultMessage: 'Portugal' }),
    PW: intl.formatMessage({ id: 'tf_widget_country_pw', defaultMessage: 'Palau' }),
    PY: intl.formatMessage({ id: 'tf_widget_country_py', defaultMessage: 'Paraguay' }),
    QA: intl.formatMessage({ id: 'tf_widget_country_qa', defaultMessage: 'Qatar' }),
    RE: intl.formatMessage({ id: 'tf_widget_country_re', defaultMessage: 'Réunion' }),
    RO: intl.formatMessage({ id: 'tf_widget_country_ro', defaultMessage: 'Romania' }),
    RS: intl.formatMessage({ id: 'tf_widget_country_rs', defaultMessage: 'Serbia' }),
    RU: intl.formatMessage({ id: 'tf_widget_country_ru', defaultMessage: 'Russian Federation' }),
    RW: intl.formatMessage({ id: 'tf_widget_country_rw', defaultMessage: 'Rwanda' }),
    SA: intl.formatMessage({ id: 'tf_widget_country_sa', defaultMessage: 'Saudi Arabia' }),
    SB: intl.formatMessage({ id: 'tf_widget_country_sb', defaultMessage: 'Solomon Islands' }),
    SC: intl.formatMessage({ id: 'tf_widget_country_sc', defaultMessage: 'Seychelles' }),
    SD: intl.formatMessage({ id: 'tf_widget_country_sd', defaultMessage: 'Sudan' }),
    SE: intl.formatMessage({ id: 'tf_widget_country_se', defaultMessage: 'Sweden' }),
    SG: intl.formatMessage({ id: 'tf_widget_country_sg', defaultMessage: 'Singapore' }),
    SH: intl.formatMessage({ id: 'tf_widget_country_sh', defaultMessage: 'Saint Helena' }),
    SI: intl.formatMessage({ id: 'tf_widget_country_si', defaultMessage: 'Slovenia' }),
    SJ: intl.formatMessage({ id: 'tf_widget_country_sj', defaultMessage: 'Svalbard And Jan Mayen' }),
    SK: intl.formatMessage({ id: 'tf_widget_country_sk', defaultMessage: 'Slovakia' }),
    SL: intl.formatMessage({ id: 'tf_widget_country_sl', defaultMessage: 'Sierra Leone' }),
    SM: intl.formatMessage({ id: 'tf_widget_country_sm', defaultMessage: 'San Marino' }),
    SN: intl.formatMessage({ id: 'tf_widget_country_sn', defaultMessage: 'Senegal' }),
    SO: intl.formatMessage({ id: 'tf_widget_country_so', defaultMessage: 'Somalia' }),
    SR: intl.formatMessage({ id: 'tf_widget_country_sr', defaultMessage: 'Suriname' }),
    SS: intl.formatMessage({ id: 'tf_widget_country_ss', defaultMessage: 'South Sudan' }),
    ST: intl.formatMessage({ id: 'tf_widget_country_st', defaultMessage: 'Sao Tome and Principe' }),
    SV: intl.formatMessage({ id: 'tf_widget_country_sv', defaultMessage: 'El Salvador' }),
    SX: intl.formatMessage({ id: 'tf_widget_country_sx', defaultMessage: 'Sint Maarten' }),
    SY: intl.formatMessage({ id: 'tf_widget_country_sy', defaultMessage: 'Syrian Arab Republic' }),
    SZ: intl.formatMessage({ id: 'tf_widget_country_sz', defaultMessage: 'Swaziland' }),
    TC: intl.formatMessage({ id: 'tf_widget_country_tc', defaultMessage: 'Turks and Caicos Islands' }),
    TD: intl.formatMessage({ id: 'tf_widget_country_td', defaultMessage: 'Chad' }),
    TG: intl.formatMessage({ id: 'tf_widget_country_tg', defaultMessage: 'Togo' }),
    TH: intl.formatMessage({ id: 'tf_widget_country_th', defaultMessage: 'Thailand' }),
    TJ: intl.formatMessage({ id: 'tf_widget_country_tj', defaultMessage: 'Tajikistan' }),
    TK: intl.formatMessage({ id: 'tf_widget_country_tk', defaultMessage: 'Tokelau' }),
    TL: intl.formatMessage({ id: 'tf_widget_country_tl', defaultMessage: 'Timor-Leste' }),
    TM: intl.formatMessage({ id: 'tf_widget_country_tm', defaultMessage: 'Turkmenistan' }),
    TN: intl.formatMessage({ id: 'tf_widget_country_tn', defaultMessage: 'Tunisia' }),
    TO: intl.formatMessage({ id: 'tf_widget_country_to', defaultMessage: 'Tonga' }),
    TR: intl.formatMessage({ id: 'tf_widget_country_tr', defaultMessage: 'Turkey' }),
    TT: intl.formatMessage({ id: 'tf_widget_country_tt', defaultMessage: 'Trinidad and Tobago' }),
    TV: intl.formatMessage({ id: 'tf_widget_country_tv', defaultMessage: 'Tuvalu' }),
    TW: intl.formatMessage({ id: 'tf_widget_country_tw', defaultMessage: 'Taiwan, Republic Of China' }),
    TZ: intl.formatMessage({ id: 'tf_widget_country_tz', defaultMessage: 'Tanzania, United Republic of' }),
    UA: intl.formatMessage({ id: 'tf_widget_country_ua', defaultMessage: 'Ukraine' }),
    UG: intl.formatMessage({ id: 'tf_widget_country_ug', defaultMessage: 'Uganda' }),
    US: intl.formatMessage({ id: 'tf_widget_country_us', defaultMessage: 'United States' }),
    UY: intl.formatMessage({ id: 'tf_widget_country_uy', defaultMessage: 'Uruguay' }),
    UZ: intl.formatMessage({ id: 'tf_widget_country_uz', defaultMessage: 'Uzbekistan' }),
    VA: intl.formatMessage({ id: 'tf_widget_country_va', defaultMessage: 'Holy See (Vatican City State)' }),
    VC: intl.formatMessage({ id: 'tf_widget_country_vc', defaultMessage: 'Saint Vincent And The Grenadines' }),
    VE: intl.formatMessage({ id: 'tf_widget_country_ve', defaultMessage: 'Venezuela, Bolivarian Republic of' }),
    VG: intl.formatMessage({ id: 'tf_widget_country_vg', defaultMessage: 'Virgin Islands, British' }),
    VI: intl.formatMessage({ id: 'tf_widget_country_vi', defaultMessage: 'Virgin Islands, U.S.' }),
    VN: intl.formatMessage({ id: 'tf_widget_country_vn', defaultMessage: 'Vietnam' }),
    VU: intl.formatMessage({ id: 'tf_widget_country_vu', defaultMessage: 'Vanuatu' }),
    WF: intl.formatMessage({ id: 'tf_widget_country_wf', defaultMessage: 'Wallis and Futuna' }),
    WS: intl.formatMessage({ id: 'tf_widget_country_ws', defaultMessage: 'Samoa' }),
    YE: intl.formatMessage({ id: 'tf_widget_country_ye', defaultMessage: 'Yemen' }),
    YT: intl.formatMessage({ id: 'tf_widget_country_yt', defaultMessage: 'Mayotte' }),
    ZA: intl.formatMessage({ id: 'tf_widget_country_za', defaultMessage: 'South Africa' }),
    ZM: intl.formatMessage({ id: 'tf_widget_country_zm', defaultMessage: 'Zambia' }),
    ZW: intl.formatMessage({ id: 'tf_widget_country_zw', defaultMessage: 'Zimbabwe' }),
  };
}
