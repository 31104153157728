import {
  dateCopy,
  dateToTimeslot,
  formatDateWithTimeslot,
  formatForGraphQl,
  timeslotToTimeHHMM,
} from '@/utils/date-utils';
import { useRestaurantDate } from '@/contexts/RestaurantDateProvider/RestaurantDateProvider';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { ConfigurationItemKeys } from '@/types/config';
import { WizardBooking, WizardStep } from '@/types/WizardBooking';
import { getConfigurationValue } from '@/utils/configuration-utils';
import { pickBy } from 'lodash';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { ReservationIntentOriginEnum } from '@/graphql/types.generated';
import { captureException } from '@/utils/captureException';

export const buildThankYouPageUrl = (
  thankYouPageUrl: string,
  { date, time, pax }: { date: string; time: string; pax?: number },
  locale: string,
): URL | null => {
  try {
    const paxUrl = '<%partySize%>';
    const dateUrl = '<%date%>';
    const hourUrl = '<%hour%>';
    const langUrl = '<%lang%>';

    const thankYouPageUrlWithData = new URL(
      thankYouPageUrl
        .replace(paxUrl, String(pax))
        .replace(dateUrl, formatDateWithTimeslot(date, Number(time), { onlyDate: true }))
        .replace(hourUrl, timeslotToTimeHHMM(Number(time)))
        .replace(langUrl, String(locale)),
    );
    return thankYouPageUrlWithData;
  } catch (error) {
    captureException(new Error(`Error while building the thank you page URL: ${error}`));
    return null;
  }
};

const bookingPath = [
  {
    step: WizardStep.Blank,
    shouldShow: (bookingState: StepParams) => bookingState.step === undefined && !bookingState.reservationUuid,
  },
  {
    step: WizardStep.Playground,
    shouldShow: (bookingState: StepParams) => bookingState.step === WizardStep.Playground,
  },
  {
    step: WizardStep.Pax,
    shouldShow: (bookingState: StepParams) => bookingState.step === WizardStep.Pax,
  },
  {
    step: WizardStep.Date,
    shouldShow: (bookingState: StepParams) => bookingState.step === WizardStep.Date,
  },
  {
    step: WizardStep.Hour,
    shouldShow: (bookingState: StepParams) => bookingState.step === WizardStep.Hour,
  },
  {
    step: WizardStep.CouponRecap,
    shouldShow: (bookingState: StepParams) => bookingState.step === WizardStep.CouponRecap,
  },
  {
    step: WizardStep.Result,
    shouldShow: (bookingState: StepParams) => bookingState.step === WizardStep.Result,
  },
  {
    step: WizardStep.Offer,
    shouldShow: (bookingState: StepParams) => bookingState.step === WizardStep.Offer,
  },
  {
    step: WizardStep.UserInformation,
    shouldShow: (bookingState: StepParams) => bookingState.step === WizardStep.UserInformation,
  },
  {
    step: WizardStep.MoreInfo,
    shouldShow: (bookingState: StepParams) => bookingState.step === WizardStep.MoreInfo,
  },
  {
    step: WizardStep.Payment,
    shouldShow: (bookingState: StepParams) => bookingState.step === WizardStep.Payment && !bookingState.couponCode,
  },
  {
    step: WizardStep.Error,
    shouldShow: (bookingState: StepParams) => bookingState.step === WizardStep.Error,
  },
  {
    step: WizardStep.Success,
    shouldShow: (bookingState: StepParams) => !!bookingState.reservationUuid,
  },
];

type StepParams = WizardBooking;

const calculateStep = (booking: StepParams): WizardStep => {
  const res = bookingPath.find(({ shouldShow }) => shouldShow(booking))?.step || WizardStep.Blank;
  return res;
};

export const transformNavigationParams = (
  navigationParam: null | undefined | string,
  defaultParam: string | undefined,
) => {
  if (navigationParam === null) {
    return undefined;
  }

  return navigationParam ?? defaultParam;
};

export const getDefaultDhpDate = (dateParam: Date, delay?: number): Date => {
  const DEFAULT_DELAY = 30;
  const LUNCH_INTERVAL = [720, 900]; // 12:00 - 15:00
  const DINER_INTERVAL = [1140, 1380]; // 19:00 - 23:00
  const rounded = delay || DEFAULT_DELAY; // 15min or 30min, 30min as default.
  const date = dateCopy(dateParam);
  date.setMinutes(Math.round(date.getMinutes() / rounded) * rounded);
  date.setMinutes(date.getMinutes() + rounded);
  date.setSeconds(0);
  const dateMinutes = date.getHours() * 60 + date.getMinutes();
  if (dateMinutes < LUNCH_INTERVAL[0]) {
    // Example: 8:35 --> 12:00
    date.setHours(Math.floor(LUNCH_INTERVAL[0] / 60));
    date.setMinutes(Math.floor(LUNCH_INTERVAL[0] % 60));
  } else if (dateMinutes > LUNCH_INTERVAL[1] && dateMinutes < DINER_INTERVAL[0]) {
    // Example: 15:05 --> 19:00
    date.setHours(Math.floor(DINER_INTERVAL[0] / 60));
    date.setMinutes(Math.floor(DINER_INTERVAL[0] % 60));
  } else if (dateMinutes > DINER_INTERVAL[1]) {
    // Example: 23:05 --> Tomorrow, 12:00
    date.setDate(date.getDate() + 1);
    date.setHours(Math.floor(LUNCH_INTERVAL[0] / 60));
    date.setMinutes(Math.floor(LUNCH_INTERVAL[0] % 60));
  }
  return date;
};

export const useDefaultDhpParams = () => {
  const { widgetConfig } = useWidgetConfig();
  const { restaurantDate } = useRestaurantDate();
  const delay = widgetConfig?.restaurant?.delay || 30; // TODO: remove default
  const dateRounded = getDefaultDhpDate(restaurantDate, delay);
  return {
    pax: 2,
    date: formatForGraphQl(dateRounded),
    time: String(dateToTimeslot(dateRounded)),
  };
};

export const calculateBookingState = ({
  restaurantUuid,
  pax,
  date,
  time,
  offer,
  step,
  crossSellUuid,
  customerUuid,
  reservationUuid,
  theForkNewsletterOptIn,
  restaurantNewsletterOptIn,
  areaUuid,
  customFields,
  result,
  isWaitingList,
  specialRequest,
  mealDuration,
  couponCode,
  utm_campaign,
  utm_medium,
  utm_source,
  reservationIntentUuid,
  origin,
}: ParsedUrlQuery) => {
  const bookingParams = {
    pax: pax != null ? Number(pax) : undefined,
    date: date?.toString(),
    time: time?.toString(),
    offer: offer?.toString(),
    step: step?.toString(),
    crossSellUuid: crossSellUuid?.toString(),
    customerUuid: customerUuid?.toString(),
    reservationUuid: reservationUuid?.toString(),
    theForkNewsletterOptIn: theForkNewsletterOptIn === 'true',
    restaurantNewsletterOptIn: restaurantNewsletterOptIn === 'true',
    areaUuid: areaUuid?.toString(),
    customFields: customFields?.toString(),
    result: !!result,
    isWaitingList: !!isWaitingList,
    specialRequest: specialRequest?.toString(),
    mealDuration: mealDuration != null ? Number(mealDuration) : undefined,
    couponCode: couponCode?.toString(),
    utm_campaign: utm_campaign?.toString(),
    utm_medium: utm_medium?.toString(),
    utm_source: utm_source?.toString(),
    reservationIntentUuid: reservationIntentUuid?.toString(),
    origin: origin?.toString() as ReservationIntentOriginEnum | undefined,
  };

  const calculatedStep = calculateStep(bookingParams);

  return {
    restaurantUuid: restaurantUuid?.toString(),
    bookingParams,
    step: calculatedStep,
  };
};

type StepOptions = {
  replaceHistory?: boolean;
  onlyKeepDHP?: boolean;
};

const previousSteps: string[] = ['blank'];

export const useBooking = () => {
  const router = useRouter();
  const { restaurantUuid, step, bookingParams } = calculateBookingState(router.query);
  const { widgetConfig } = useWidgetConfig();
  const defaultDhpParams = useDefaultDhpParams();

  const isThankYouPageEnabled =
    getConfigurationValue(widgetConfig?.settings, ConfigurationItemKeys.INTEGRATION_THANK_YOU_PAGE_ENABLE, '') ===
    'true';

  function getCustomQueryParams() {
    const customQueryParams = { ...router.query }; // Create a copy of the router.query object
    Object.keys(customQueryParams).forEach((key) => {
      if (key in UrlQueryParams) {
        delete customQueryParams[key];
      }
    });
    return customQueryParams;
  }

  const handleStepperUndo = () => {
    const hasHistory = window.history.state !== null && window.history.length > 1;
    // If page is either on DHP or has no history: redirect to the initial page.
    if ([WizardStep.Result, WizardStep.Error].includes(step) || !hasHistory) {
      return handleSelectStep({
        step: WizardStep.Blank,
      });
    }
    return router.back();
  };

  const handleStepperGoBack = () => {
    const hasHistory = window.history.state !== null && window.history.length > 1;
    // If page is either on DHP or has no history: redirect to the initial page.
    if ([WizardStep.Result, WizardStep.Error].includes(step) || !hasHistory) {
      return handleSelectStep({
        step: WizardStep.Blank,
      });
    }
  };

  const handleSelectStep = (
    {
      pax,
      date,
      time,
      offer,
      step,
      crossSellUuid,
      reservationUuid,
      customerUuid,
      theForkNewsletterOptIn,
      restaurantNewsletterOptIn,
      areaUuid,
      customFields,
      isWaitingList,
      specialRequest,
      mealDuration,
      couponCode,
      reservationIntentUuid,
      origin,
    }: StepParams,
    options: StepOptions = {},
  ) => {
    if (Boolean(bookingParams.couponCode) && step === WizardStep.Offer) {
      step = WizardStep.UserInformation;
    }

    if (step) {
      previousSteps.push(step);
    }

    const params = {
      query: pickBy(
        {
          ...router.query,
          restaurantUuid,
          pax: pax ?? bookingParams.pax,
          date: date ?? bookingParams.date,
          time: time ?? bookingParams.time,
          offer: offer ?? bookingParams.offer,
          step: step ?? bookingParams.step,
          crossSellUuid: crossSellUuid ?? bookingParams.crossSellUuid,
          reservationUuid: reservationUuid ?? bookingParams.reservationUuid,
          customerUuid: customerUuid ?? bookingParams.customerUuid,
          theForkNewsletterOptIn: theForkNewsletterOptIn ?? bookingParams.theForkNewsletterOptIn,
          restaurantNewsletterOptIn: restaurantNewsletterOptIn ?? bookingParams.restaurantNewsletterOptIn,
          areaUuid: areaUuid ?? bookingParams.areaUuid,
          customFields: customFields ?? bookingParams.customFields,
          isWaitingList: isWaitingList ?? bookingParams.isWaitingList,
          specialRequest: specialRequest ?? bookingParams.specialRequest,
          mealDuration: mealDuration ?? bookingParams.mealDuration,
          couponCode: transformNavigationParams(couponCode, bookingParams.couponCode),
          reservationIntentUuid: reservationIntentUuid ?? bookingParams.reservationIntentUuid,
          origin: origin ?? bookingParams.origin,
        },
        Boolean, // keep only non falsy values, works because pax === 0 is an invalid value
      ),
    };

    // Removes all the params in the URL in order to reset the saved data when an error occurs,
    if (options.onlyKeepDHP) {
      params.query = {
        restaurantUuid,
        pax: params.query.pax,
        date: params.query.date,
        time: params.query.time,
        step: params.query.step,
      };
    }

    // All the steps in the checkout flow use the payment data so they must be declared as "non-shallow" steps to ensure
    // that every time a step is loaded we refresh the payment data during the page SSR (the redirect to a step with "shallow: false" let rerender the page with SSR).
    const nonShallowSteps = [WizardStep.Offer, WizardStep.UserInformation, WizardStep.MoreInfo, WizardStep.Payment].map(
      (step) => String(step),
    );

    // Note: steps after DHP must have DHP params to work.
    const dhpStepList = [
      WizardStep.Blank,
      WizardStep.Date,
      WizardStep.Hour,
      WizardStep.Pax,
      WizardStep.CouponRecap,
    ].map((step) => String(step));

    const isInDhp = dhpStepList.includes(String(step));
    const isShallow = !nonShallowSteps.includes(String(step));
    if (!isInDhp) {
      if (!params.query.pax) {
        params.query.pax = defaultDhpParams.pax;
      }
      if (!params.query.date) {
        params.query.date = defaultDhpParams.date;
      }
      if (!params.query.time) {
        params.query.time = defaultDhpParams.time;
      }
    }
    return options.replaceHistory
      ? router.replace(params, undefined, { shallow: isShallow })
      : router.push(params, undefined, { shallow: isShallow });
  };

  const handleErrorGoBack = () => {
    router.push({
      pathname: '/[restaurantUuid]',
      query: {
        ...getCustomQueryParams(),
        restaurantUuid,
      },
    });
  };

  type ReservationIdType = string | null | undefined;

  enum UrlQueryParams {
    restaurantUuid = 'restaurantUuid',
    pax = 'pax',
    date = 'date',
    time = 'time',
    offer = 'offer',
    step = 'step',
    crossSellUuid = 'crossSellUuid',
    customerUuid = 'customerUuid',
    reservationUuid = 'reservationUuid',
    theForkNewsletterOptIn = 'theForkNewsletterOptIn',
    restaurantNewsletterOptIn = 'restaurantNewsletterOptIn',
    area = 'area',
    table = 'table',
    customFields = 'customFields',
    result = 'result',
    isWaitingList = 'isWaitingList',
    specialRequest = 'specialRequest',
    mealDuration = 'mealDuration',
    couponCode = 'couponCode',
    reservationIntentUuid = 'reservationIntentUuid',
    origin = 'origin',
  }

  const handleContactFormSuccess = (reservationUuid: ReservationIdType) => {
    const customQueryParams = getCustomQueryParams();

    const routeToSuccessPage = () => {
      router.push(
        {
          query: {
            ...customQueryParams,
            restaurantUuid,
            reservationUuid,
          },
        },
        undefined,
        { shallow: true },
      );
    };

    if (!isThankYouPageEnabled) {
      return routeToSuccessPage();
    }
    const thankYouPageUrl = getConfigurationValue(
      widgetConfig?.settings,
      ConfigurationItemKeys.INTEGRATION_THANK_YOU_PAGE_URL,
      '',
    );
    if (!thankYouPageUrl || !bookingParams.date || !bookingParams.time || !router.locale) {
      return routeToSuccessPage();
    }

    const thankYouPageUrlWithData = buildThankYouPageUrl(
      thankYouPageUrl,
      { date: bookingParams.date, time: bookingParams.time, pax: bookingParams.pax },
      router.locale,
    );

    if (!thankYouPageUrlWithData) {
      return routeToSuccessPage();
    }

    //Append the custom query params to the thank you page URL
    Object.entries(customQueryParams).forEach(([key, value]) => {
      thankYouPageUrlWithData.searchParams.append(key, value?.toString() || '');
    });

    window.open(thankYouPageUrlWithData, '_parent');
    // if (window.self !== window.top) {
    //   // The widget is loaded through an IFrame, open the thank you page in a new window additionally to the redirection.
    //   window.open(thankYouPageUrlWithData, '_parent');
    //   return router.push(
    //     {
    //       query: {
    //         restaurantUuid,
    //       },
    //     },
    //     undefined,
    //     { shallow: true },
    //   );
    // } else {
    //   // Else, redirect to the custom thank you page.
    //   return router.push(thankYouPageUrl);
    //   //return;
    // }
  };

  return {
    restaurantUuid,
    bookingParams,
    step,
    previousSteps,
    handleSelectStep,
    handleStepperUndo,
    handleStepperGoBack,
    handleContactFormSuccess,
    handleErrorGoBack,
  };
};
