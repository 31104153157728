import { CivilityEnum } from '@/graphql/types.generated';

export const civilityMapping = {
  [CivilityEnum.MrAndMrs]: {
    id: 'tf_widget_civility_mrms',
    defaultMessage: 'Mr and Mrs',
  },
  [CivilityEnum.Doctor]: {
    id: 'tf_widget_civility_doctor',
    defaultMessage: 'Doctor',
  },
  [CivilityEnum.Professor]: {
    id: 'tf_widget_civility_professor',
    defaultMessage: 'Professor',
  },
  [CivilityEnum.Counsel]: {
    id: 'tf_widget_civility_counsel',
    defaultMessage: 'Counsel',
  },
  [CivilityEnum.Ambassador]: {
    id: 'tf_widget_civility_ambassador',
    defaultMessage: 'Ambassador',
  },
  [CivilityEnum.MadamAmbassador]: {
    id: 'tf_widget_civility_madamAmbassador',
    defaultMessage: 'Madam Ambassador',
  },
  [CivilityEnum.President]: {
    id: 'tf_widget_civility_president',
    defaultMessage: 'President',
  },
  [CivilityEnum.MadamPresident]: {
    id: 'tf_widget_civility_madamPresident',
    defaultMessage: 'Madam President',
  },
  [CivilityEnum.Magister]: {
    id: 'tf_widget_civility_magister',
    defaultMessage: 'Magister',
  },
  [CivilityEnum.Chef]: {
    id: 'tf_widget_civility_chef',
    defaultMessage: 'Chef',
  },
  [CivilityEnum.Prince]: {
    id: 'tf_widget_civility_prince',
    defaultMessage: 'Prince',
  },
  [CivilityEnum.Princess]: {
    id: 'tf_widget_civility_princess',
    defaultMessage: 'Princess',
  },
  [CivilityEnum.Count]: {
    id: 'tf_widget_civility_count',
    defaultMessage: 'Count',
  },
  [CivilityEnum.Countess]: {
    id: 'tf_widget_civility_countess',
    defaultMessage: 'Countess',
  },
  [CivilityEnum.Duke]: {
    id: 'tf_widget_civility_duke',
    defaultMessage: 'Duke',
  },
  [CivilityEnum.Duchess]: {
    id: 'tf_widget_civility_duchess',
    defaultMessage: 'Duchess',
  },
  [CivilityEnum.Sir]: {
    id: 'tf_widget_civility_sir',
    defaultMessage: 'Sir',
  },
  [CivilityEnum.Lord]: {
    id: 'tf_widget_civility_lord',
    defaultMessage: 'Lord',
  },
  [CivilityEnum.Lady]: {
    id: 'tf_widget_civility_lady',
    defaultMessage: 'Lady',
  },
  [CivilityEnum.Highness]: {
    id: 'tf_widget_civility_highness',
    defaultMessage: 'Highness',
  },
  [CivilityEnum.Baron]: {
    id: 'tf_widget_civility_baron',
    defaultMessage: 'Baron',
  },
  [CivilityEnum.Baroness]: {
    id: 'tf_widget_civility_baroness',
    defaultMessage: 'Baroness',
  },
  [CivilityEnum.Company]: {
    id: 'tf_widget_civility_company',
    defaultMessage: 'Company',
  },
};
