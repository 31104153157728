import { SdkType } from '@/graphql/client-backend.generated';
import { formatDateWithTimeslot } from './date-utils';

import getConfig from 'next/config';

import { PUBLISHABLE_KEY_LOCALES } from '@/utils/constants';
import { PaymentData, StripeAuth } from '@/types/payment';
import { captureException } from '@/utils/captureException';

// This will only be used by the [restaurantUuid]/index.tsx page
// I would suggest changing the structure of the app to only contain pages components
// and move all the logic to the an "app" folder mirroring the pages folder
// to allow for grouping of related files
// eg: pages/[restaurantUuid]/index.tsx -> app/[restaurantUuid]/[page.tsx, utils.ts, types.ts,...]

const getLocaleFromStripePublishableKey = (publishableKey: string): string | undefined => {
  return PUBLISHABLE_KEY_LOCALES[publishableKey];
};

export const getStripePublishableKey = (stripePublishableKeyIdentifier?: string | null): string => {
  const {
    publicRuntimeConfig: {
      stripe: { publishableKeys },
    },
  } = getConfig();

  const locale = stripePublishableKeyIdentifier
    ? getLocaleFromStripePublishableKey(stripePublishableKeyIdentifier)
    : undefined;

  const key = locale ? publishableKeys[locale] : undefined;

  if (!key) {
    throw new Error(`[StripeProvider] Missing publishableKey for identifier ${stripePublishableKeyIdentifier}`);
  }
  return key;
};

const getStripeAuthData = async (
  graphQlSdk: SdkType,
  setupAuthData: boolean,
  reservationIntentUuid?: string,
): Promise<StripeAuth | null> => {
  if (!setupAuthData || !reservationIntentUuid) {
    return null;
  }
  const {
    data: { getReservationIntentByUuid },
  } = await graphQlSdk.getPaymentGuaranteeByReservationIntentUuidQuery({ variables: { reservationIntentUuid } });

  if (!getReservationIntentByUuid.paymentGuaranteeIntent) {
    captureException(new Error(`No payment guarantee intent found for reservation intent: ${reservationIntentUuid}`));
    return null;
  }

  return {
    paymentGuaranteeIntent: { ...getReservationIntentByUuid.paymentGuaranteeIntent },
    publishableKey: getStripePublishableKey(
      getReservationIntentByUuid.paymentGuaranteeIntent.stripePublishableKeyIdentifier,
    ),
  };
};

export const getPaymentConfig = async ({
  graphQlSdk,
  restaurantUuid,
  pax,
  time,
  date,
  offer,
  couponCode,
  reservationIntentUuid,
  setupAuthData = false,
}: {
  graphQlSdk: SdkType;
  restaurantUuid: string;
  pax: number;
  time: string;
  date: string;
  offer?: string;
  couponCode?: string;
  reservationIntentUuid?: string;
  setupAuthData?: boolean;
}): Promise<PaymentData> => {
  const dateTime = formatDateWithTimeslot(date, Number(time), { withSecond: true });
  const creditCardSettings = await graphQlSdk.reservationCreditCardSettingsQuery({
    variables: {
      reservation: {
        restaurantUuid,
        partySize: pax,
        offerUuid: offer,
        dateTime,
      },
    },
  });

  const { reservationCreditCardSettings } = creditCardSettings.data;
  if (
    couponCode ||
    !reservationCreditCardSettings ||
    (!reservationCreditCardSettings.imprint && !reservationCreditCardSettings.prepayment) ||
    !reservationCreditCardSettings.paymentProvider ||
    reservationCreditCardSettings.paymentProvider.name !== 'STRIPE'
  ) {
    return {
      needsPayment: false,
    };
  }

  const authData = await getStripeAuthData(graphQlSdk, setupAuthData, reservationIntentUuid);
  return {
    needsPayment: true,
    config: {
      imprint: reservationCreditCardSettings.imprint,
      prepayment: reservationCreditCardSettings.prepayment,
      amount: reservationCreditCardSettings.amount,
      minimumCancellationHours: reservationCreditCardSettings.minimumCancellationHours,
      bookingPolicy: reservationCreditCardSettings.bookingPolicy,
      cancellationPolicy: reservationCreditCardSettings.cancellationPolicy,
      currency: reservationCreditCardSettings.currency,
      paymentProvider: {
        name: reservationCreditCardSettings.paymentProvider.name,
        isAutoRefundEnabled: reservationCreditCardSettings.paymentProvider.isAutoRefundEnabled,
        authData,
      },
    },
  };
};
