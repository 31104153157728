import styled from '@emotion/styled';
import { Button } from '@/components/Button/Button';

export const CouponButton = styled(Button)`
  width: 100%;
  margin-top: ${({ theme }) => theme.space.s};
`;

export const CouponCodeForm = styled.form`
  text-align: left;
  display: flex;
  min-height: ${({ theme }) => theme.space.xxl};
  width: 100%;
`;

export const CheckButton = styled(Button)`
  flex: 1;
  margin-left: ${({ theme }) => theme.space.s};
  margin-top: ${({ theme }) => theme.space.m};
  max-height: ${({ theme }) => theme.space.xxl};
`;

export const InputContainer = styled.div`
  flex: 8;
`;
