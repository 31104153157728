import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '@/components/ReactHookForm/Input';
import { IconVoucher } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { VoucherDocument } from '@/graphql/types.generated';
import { useLazyQuery } from '@apollo/client';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { useBooking } from '@/hooks/useBooking';
import { WizardStep } from '@/types/WizardBooking';
import * as S from './CouponCode.styles';

export const CouponCode = ({}) => {
  const intl = useIntl();
  const { handleSelectStep, bookingParams } = useBooking();
  const { widgetConfig } = useWidgetConfig();
  const [isExpanded, setIsExpanded] = useState(false);
  const [getVoucher, { loading }] = useLazyQuery(VoucherDocument);

  const onSubmit = (data: { couponCode: string }) => {
    handleSelectStep({ couponCode: data.couponCode, step: WizardStep.CouponRecap });
  };

  const { handleSubmit, control, watch, clearErrors } = useForm<{ couponCode: string }>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: {
      couponCode: '',
    },
    resolver: async (formData) => {
      clearErrors();
      const { data, error } = await getVoucher({
        variables: {
          restaurantUuid: widgetConfig?.restaurant?.restaurantUuid as string,
          voucherCode: formData.couponCode,
        },
      });

      let errors = {};
      if (error || data?.voucher.isValid === false) {
        errors = {
          ...errors,
          couponCode: {
            message: intl.formatMessage({
              id: 'tf_widget_invalidcouponCode',
              defaultMessage: 'Invalid code. Please check your voucher and try again',
            }),
            type: 'validate',
          },
        };
      }
      return {
        values: formData,
        errors,
      };
    },
  });

  const couponCodeValue = watch('couponCode');

  if (bookingParams.couponCode) {
    return <div data-testid="has-coupon-code"></div>;
  }

  return (
    <>
      {!isExpanded ? (
        <S.CouponButton isSmall variant="ghost" onClick={() => setIsExpanded(true)}>
          <IconVoucher /> <FormattedMessage id="tf_widget_iHaveAGiftVoucher" defaultMessage="I have a gift voucher" />
        </S.CouponButton>
      ) : (
        <S.CouponCodeForm onSubmit={handleSubmit(onSubmit)}>
          <S.InputContainer>
            <Input
              label={intl.formatMessage({
                id: 'tf_widget_enterYourcouponCode',
                defaultMessage: 'Enter your voucher code',
              })}
              aria-labelledby=""
              id="couponCode"
              control={control}
              name="couponCode"
              placeholder={intl.formatMessage({
                id: 'tf_widget_enterYourcouponCode',
                defaultMessage: 'Enter your voucher code',
              })}
              type="text"
              prefix="coupon-input"
              required
            />
          </S.InputContainer>
          <S.CheckButton isSmall disabled={loading || couponCodeValue.length === 0} type="submit" isLoading={loading}>
            <FormattedMessage id="tf_widget_check" defaultMessage="Check" />
          </S.CheckButton>
        </S.CouponCodeForm>
      )}
    </>
  );
};
