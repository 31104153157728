import React from 'react';
import { Step } from '@/components/WizardLayout/UserCheckoutStepper/Step/Step';
import { StepProgress } from '@/components/WizardLayout/UserCheckoutStepper/StepProgress/StepProgress';
import { getPercentage, getStatus } from '@/components/WizardLayout/UserCheckoutStepper/UserCheckoutStepper.utils';
import * as S from './UserCheckoutStepper.styles';

export type UserCheckoutStepperProps = {
  currentStep: number;
  steps: number;
};

export const UserCheckoutStepper: React.FC<UserCheckoutStepperProps> = ({ currentStep, steps }) => {
  return (
    <S.UserCheckoutStepperContainer>
      <S.UserCheckoutStepper>
        {[...Array(steps - 1)].map((_, step) => (
          <React.Fragment key={step}>
            <Step status={getStatus(currentStep, step + 1)} number={step + 1} />
            <StepProgress percentage={getPercentage(currentStep, step + 1)} />
          </React.Fragment>
        ))}
        <Step status="confirmed" />
      </S.UserCheckoutStepper>
    </S.UserCheckoutStepperContainer>
  );
};
