import styled from '@emotion/styled';

export const Container = styled.div``;

export const Title = styled.div`
  ${({ theme }) => `
    font-weight: ${theme.fontWeights.m};
    margin-bottom: ${theme.space.xxs};
  `}
`;

export const Details = styled.div`
  ${({ theme }) => `
    font-weight: ${theme.fontWeights.s};
    margin-bottom: ${theme.space.xxs};
  `}
`;

export const ToggleButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-top: ${({ theme }) => theme.space.s};
`;
