import { FormattedMessage, useIntl } from 'react-intl';
import { useTheForkLogo } from './hook/useTheForkLogo';
import * as S from './TFBranding.styles';

export type TFBrandingType = {
  isFooter?: boolean;
  hasBackground?: boolean;
  isDHP?: boolean;
};

export const TFBranding = ({ isFooter = false, hasBackground = false, isDHP = false }: TFBrandingType) => {
  const intl = useIntl();
  const theForkLogo = useTheForkLogo({ isFooter, hasBackground });

  return (
    <S.HeaderOptInWrapper isDHP={isDHP} hasBackground={hasBackground}>
      <FormattedMessage
        id="tf_widget_wizard_header_subtitle"
        defaultMessage="Powered with <b> love </b> by"
        values={{
          b: (chunks) => <b>{chunks}</b>,
        }}
      />{' '}
      <img
        src={theForkLogo}
        alt={intl.formatMessage({
          id: 'tf_widget_the_fork_horizontal_logo',
          defaultMessage: 'TheFork horizontal logo',
        })}
        width={65}
      />
    </S.HeaderOptInWrapper>
  );
};
