import { useRestaurantDate } from '@/contexts/RestaurantDateProvider/RestaurantDateProvider';
import { dateCopy } from '@/utils/date-utils';
import { isSameDay } from 'date-fns';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type DateStringProps = {
  date?: Date;
};

export const DateString = ({ date }: DateStringProps) => {
  const { restaurantDate } = useRestaurantDate();
  if (!date) {
    return <FormattedMessage id="tf_widget_filters_button_dhp_date" defaultMessage="Date" />;
  }
  if (isSameDay(date, restaurantDate)) {
    return <FormattedMessage id="tf_widget_booking_today" defaultMessage="Today" />;
  }
  const restaurantDateTomorrow = dateCopy(restaurantDate).setDate(restaurantDate.getDate() + 1);
  if (isSameDay(date, restaurantDateTomorrow)) {
    return <FormattedMessage id="tf_widget_booking_tomorrow" defaultMessage="Tomorrow" />;
  }
  return <FormattedDate value={date} day="numeric" month="short" />;
};
