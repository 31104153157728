import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { WizardStep } from '@/types/WizardBooking';
import { useBooking } from '@/hooks/useBooking';
import * as S from './LanguageSelector.styles';

export type LanguageSelectorProps = {
  handleLanguageSelection: (language: string) => void;
};

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ handleLanguageSelection }) => {
  const { locale: currentLanguage } = useRouter();
  const {
    publicRuntimeConfig: { locales },
  } = getConfig();
  const { step } = useBooking();

  const isStepDHP = [WizardStep.Date, WizardStep.Hour, WizardStep.Pax].includes(step);

  const options = locales?.map((locale: string) => ({
    value: locale,
    label: locale.toUpperCase(),
  }));

  const setSelectLanguage = (value: string | null) => {
    if (value) {
      handleLanguageSelection(value);
    }
  };

  return (
    <S.Select
      id="languageSelector"
      options={options}
      onChange={setSelectLanguage}
      value={currentLanguage}
      canHaveLegend={false}
      isDisabled={isStepDHP}
      classNamePrefix="language-selector"
    />
  );
};
