import React from 'react';
import * as S from './DhpSection.styles';
import IconChevronBottom from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons/ChevronBottom';

export type DhpSectionProps = {
  Icon: React.FC;
  onClick: () => void;
  isSelected: boolean;
  testId: string;
  displayChevron: boolean;
  children?: React.ReactNode;
};

export const DhpSection: React.FC<DhpSectionProps> = ({
  children,
  onClick,
  Icon,
  isSelected,
  testId,
  displayChevron,
}) => {
  return (
    <S.DhpButton
      variant="ghost"
      onClick={onClick}
      aria-selected={isSelected}
      data-testid={testId}
      isActive={isSelected}
    >
      <Icon />
      <S.DhpButtonContent>{children}</S.DhpButtonContent>
      {displayChevron && <IconChevronBottom data-testid="chevron-icon" />}
    </S.DhpButton>
  );
};
