import styled from '@emotion/styled';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import ChiliLoader from '@lafourchette/react-chili/dist/cjs/components/Atoms/Loader';

const StyledLoader = styled(ChiliLoader)`
  color: ${({ theme }) => theme.customization.colors.text.default};
`;

export const Loader = () => {
  return (
    <Flex width="100%" p="1.5em" justifyContent="center">
      <StyledLoader data-testid="loader-icon" />
    </Flex>
  );
};
