import styled from '@emotion/styled';

export const ToggleButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-top: ${({ theme }) => theme.space.s};
`;
