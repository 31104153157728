import { useBooking } from '@/hooks/useBooking';
import { VoucherDocument } from '@/graphql/types.generated';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { Text } from '@/components/Text/Text';
import { useTheme } from '@emotion/react';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import * as S from './CouponCodePill.styles';

export const CouponCodePill = ({ currentPax }: { currentPax: number }) => {
  const theme = useTheme();
  const { bookingParams, restaurantUuid } = useBooking();
  const { data, loading } = useQuery(VoucherDocument, {
    variables: {
      restaurantUuid: String(restaurantUuid),
      voucherCode: bookingParams.couponCode ?? '',
    },
    skip: !bookingParams.couponCode,
  });

  if (loading || !bookingParams.couponCode || !data) {
    return <div data-testid="no-coupon"></div>;
  }

  const displayPaxWarning = currentPax > data.voucher.pax;

  return (
    <Box mb={theme.space.s} mt={theme.space.s}>
      <Flex justifyContent="space-between" alignItems="center">
        <S.VoucherCode variant="smallRegular">
          <S.StyledIconVoucher />{' '}
          <FormattedMessage
            id="tf_widget_voucherNumber"
            defaultMessage={'Voucher N° ...{couponCode}'}
            values={{ couponCode: bookingParams.couponCode.slice(-4) }}
          />
        </S.VoucherCode>
        <S.VoucherInformations>
          <S.StyledIconPerson />
          <S.PaxText variant="smallRegular">
            <FormattedMessage
              id="tf_widget_voucherPaxMessage"
              defaultMessage={'{count} {count, plural, one{Person} other{Persons}}'}
              values={{ count: data?.voucher.pax }}
            />
          </S.PaxText>
          <S.StyledIconRestaurantTray />
          <S.VoucherTitle variant="smallRegular" title={data.voucher.title}>
            {data.voucher.title}
          </S.VoucherTitle>
        </S.VoucherInformations>
      </Flex>
      {displayPaxWarning && (
        <Flex mt={theme.space.s}>
          <Text variant="small" textAlign="left" margin={0}>
            <FormattedMessage
              id="tf_widget_voucherPaxWarningMessage"
              defaultMessage="You have selected more guests than those included in the coupon. These will be charged normally at the Restaurant."
            />
          </Text>
        </Flex>
      )}
    </Box>
  );
};
