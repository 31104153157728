import { useState, useEffect, useCallback } from 'react';
import { parsePhoneNumberFromString, AsYouType, CountryCode, getCountries } from 'libphonenumber-js/max';

const countryIndicatorRegex = /^\+.+?\s/;

const getCountryCode = (countryCode?: string): CountryCode | undefined => {
  return getCountries().find((country) => country === countryCode);
};
/**
 * Main function in charge of the formatting
 */
const formatPhoneToDisplayData = (phoneToFormat?: string, countryCode?: CountryCode) => {
  const parsedPhone = phoneToFormat && parsePhoneNumberFromString(phoneToFormat, countryCode);

  if (!parsedPhone) {
    return {
      countryCode,
      phone: new AsYouType(countryCode).input(phoneToFormat || ''),
      number: new AsYouType().input(phoneToFormat || ''),
    };
  }

  return {
    countryCode: parsedPhone.country || countryCode,
    // To ensure coherence within the app (between forms and readonly displays),
    // we use the international format and remove the country indicator to avoid national formatting specifics
    phone: parsedPhone.formatInternational().replace(countryIndicatorRegex, ''),
    number: parsedPhone.number,
  };
};

/**
 * Hook that regroups all the phone & prefix handling
 */
export const usePhoneHandler = (initialCountryCode?: string) => {
  const countryCode = getCountryCode(initialCountryCode);
  const [phoneDisplayData, setPhoneDisplayData] = useState(() => formatPhoneToDisplayData('', countryCode));

  useEffect(() => {
    setPhoneDisplayData((prevState) => formatPhoneToDisplayData(prevState.phone.toString(), countryCode));
  }, [countryCode]);

  const handleChangeCountry = useCallback((option: { countryCode: CountryCode } | null) => {
    setPhoneDisplayData((prevState) => formatPhoneToDisplayData(prevState.phone.toString(), option?.countryCode));
  }, []);

  // When the input where user type is changing
  const handleChangePhone = useCallback((value: string) => {
    setPhoneDisplayData((prevState) => formatPhoneToDisplayData(value, prevState.countryCode));
  }, []);

  return {
    phone: phoneDisplayData.phone,
    phoneCountryCode: phoneDisplayData.countryCode,
    phoneToSubmit: phoneDisplayData.number,
    handleChangeCountry,
    handleChangePhone,
  };
};
