import styled from '@emotion/styled';
import { Text } from '@/components/Text/Text';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import { IconPerson, IconRestaurantTray, IconVoucher } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';

export const VoucherInformations = styled(Flex)`
  background-color: ${({ theme }) => theme.customization.colors.grey200};
  padding: ${({ theme }) => theme.space.xxs} ${({ theme }) => theme.space.s};
  border-radius: ${({ theme }) => theme.radii.xl};
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  > svg {
    height: 13px;
    width: 13px;
    margin: 0 ${({ theme }) => theme.space.xs};
  }
`;

export const VoucherCode = styled(Text)`
  text-align: left;
  margin: 0 ${({ theme }) => theme.space.s} 0 0;
  white-space: nowrap;
`;
export const PaxText = styled(Text)`
  margin: 0 ${({ theme }) => theme.space.xs} 0 0;
  text-align: left;
  white-space: nowrap;
`;
export const VoucherTitle = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  text-align: left;
`;

export const StyledIconPerson = styled(IconPerson)`
  flex-shrink: 0;
  width: ${({ theme }) => theme.space.m};
  height: ${({ theme }) => theme.space.m};
`;

export const StyledIconVoucher = styled(IconVoucher)`
  flex-shrink: 0;
  width: ${({ theme }) => theme.space.m};
  height: ${({ theme }) => theme.space.m};
`;

export const StyledIconRestaurantTray = styled(IconRestaurantTray)`
  flex-shrink: 0;
  width: ${({ theme }) => theme.space.m};
  height: ${({ theme }) => theme.space.m};
`;
