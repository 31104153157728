import React from 'react';
import * as S from './StepProgress.styles';

export type StepProgressProps = {
  percentage: number;
};

export const StepProgress: React.FC<StepProgressProps> = ({ percentage }) => {
  return (
    <S.ProgressBar>
      <S.ProgressBarFill progress={percentage} />
    </S.ProgressBar>
  );
};
