import styled from '@emotion/styled';
import { IconCheckmark, IconCalendarCheckmarkFill } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { px2rem } from '@lafourchette/design-system-theming/utils';
import React from 'react';
import { Theme } from '@emotion/react';

const STEP_SIZE = px2rem(24);
const STEP_FONT_SIZE = px2rem(14);

const getColor = (theme: Theme, disabled: boolean, hasIcon: boolean) => {
  if (disabled) {
    return theme.customization.colors.grey300;
  }

  return hasIcon ? theme.customization.colors.background : theme.customization.colors.accent;
};

const StyledStepContainer = styled.div<{ disabled: boolean; hasIcon: boolean }>`
  ${({ theme, disabled, hasIcon }) => `
    height: ${STEP_SIZE};
    width: ${STEP_SIZE};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${STEP_FONT_SIZE};
    font-weight: bold;
    border-radius: 50%;
    background-color: ${hasIcon && !disabled ? theme.customization.colors.accent : 'transparent'};
    color: ${getColor(theme, disabled, hasIcon)};
    border: 2px solid ${disabled ? theme.customization.colors.grey300 : theme.customization.colors.accent};
    padding: ${theme.space.xs};
  `};
`;

export const Step: React.FC<{ disabled: boolean; children: React.ReactNode }> = ({ disabled, children }) => (
  <StyledStepContainer disabled={disabled} hasIcon={false}>
    {children}
  </StyledStepContainer>
);

export const StepCompleted = () => (
  <StyledStepContainer disabled={false} hasIcon>
    <IconCheckmark data-testid="icon-checkmark" />
  </StyledStepContainer>
);

export const StepConfirmation = () => (
  <StyledStepContainer disabled hasIcon>
    <IconCalendarCheckmarkFill data-testid="icon-calendar-checkmark-fill" />
  </StyledStepContainer>
);
