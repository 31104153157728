import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { IconTriangleBottom, IconTriangleTop } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { TranslationItemKeys } from '@/types/config';
import { useIntl } from 'react-intl';
import { getTranslationValue } from '@/utils/translation-utils';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { useBooking } from '@/hooks/useBooking';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import * as S from './Mention.styles';

export interface MentionProps {
  isActive?: boolean;
  title?: string;
  content?: string;
}

export const Mention: React.FC<MentionProps> = ({ title, content, isActive }) => {
  const [isDetailOpened, setIsDetailOpened] = useState(false);
  const { widgetConfig } = useWidgetConfig();
  const { step } = useBooking();
  const intl = useIntl();

  const isActiveMention =
    getTranslationValue(widgetConfig?.settings, TranslationItemKeys.TRANSLATION_ACTIVE_MENTION, 'false') === 'true' ||
    isActive;
  const mainMention =
    getTranslationValue(widgetConfig?.settings, TranslationItemKeys.TRANSLATION_MAIN_MENTION) || title;
  const suppMention =
    getTranslationValue(widgetConfig?.settings, TranslationItemKeys.TRANSLATION_SUPP_MENTION) || content;

  if (!isActiveMention || !mainMention) {
    return null;
  }

  return (
    <S.SnackWrapper step={step} variant="information" data-testid="snack-wrapper">
      <Flex>{mainMention}</Flex>
      {!!suppMention && (
        <>
          <AnimatePresence>
            <motion.div
              initial={{ height: 0, overflow: 'hidden' }}
              animate={{ height: isDetailOpened ? 'auto' : 0 }}
              exit={{ height: 0 }}
              transition={{ duration: 0.25 }}
            >
              <S.Secondary>{suppMention}</S.Secondary>
            </motion.div>
          </AnimatePresence>
          {isDetailOpened ? (
            <S.ToggleButton onClick={() => setIsDetailOpened(!isDetailOpened)}>
              {intl.formatMessage({
                id: 'tf_widget_notice_hide',
                defaultMessage: 'HIDE',
              })}
              <IconTriangleTop />
            </S.ToggleButton>
          ) : (
            <S.ToggleButton onClick={() => setIsDetailOpened(!isDetailOpened)}>
              {intl.formatMessage({
                id: 'tf_widget_notice_show',
                defaultMessage: 'SHOW',
              })}
              <IconTriangleBottom />
            </S.ToggleButton>
          )}
        </>
      )}
    </S.SnackWrapper>
  );
};
