import { useEffect, useRef, useCallback, ChangeEvent } from 'react';
import { InputInvalid } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/Input';
import { IconExclamationmarkCircleFill } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { PhoneCountryCodeSelect } from '@/react-chili/PhoneCountryCodeSelect/PhoneCountryCodeSelect';
import { usePhoneHandler } from './usePhoneHandler';
import { getExamplePhoneNumber } from '@/react-chili/PhoneCountryCodeSelect/countries';
import * as S from './PhoneSelect.styles';

export type PhoneSelectProps = {
  disabled?: boolean;
  required?: boolean;
  name?: string;
  onChange: (phoneToSubmit?: string) => void;
  onBlur?: (inputRef: HTMLInputElement | null, phoneToSubmit?: string) => void;
  touched?: boolean;
  error?: string;
  countryCode: string;
  className?: string;
  placeholder?: string;
  label?: string;
  prefix?: string;
};

export const PhoneSelect = ({
  countryCode,
  error,
  className,
  disabled = false,
  required = false,
  label,
  name,
  prefix,
  onChange,
  onBlur,
}: PhoneSelectProps) => {
  const { phone, phoneCountryCode, phoneToSubmit, handleChangeCountry, handleChangePhone } =
    usePhoneHandler(countryCode);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (onChange && phone.length > 0) {
      onChange(phoneToSubmit);
    }
  }, [phoneToSubmit, phone, phoneCountryCode, onChange]);

  const handleChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangePhone(e.target.value),
    [handleChangePhone],
  );

  const handleBlur = useCallback(() => {
    if (onChange) {
      onChange(phoneToSubmit);
    }
    if (onBlur) {
      onBlur(inputRef.current, phoneToSubmit);
    }
  }, [onChange, onBlur, phoneToSubmit]);

  return (
    <div className={className}>
      <S.Wrapper>
        <S.InputLabel>
          {label}
          {required && <S.RequiredAsterisk />}
        </S.InputLabel>
        <S.Field>
          <PhoneCountryCodeSelect
            country={phoneCountryCode || countryCode}
            onChange={handleChangeCountry}
            onBlur={handleBlur}
            disabled={disabled}
            data-testid={`${prefix}-country`}
          />
          <S.MainInput
            type="tel"
            id={`${prefix}-number`}
            data-testid={`${prefix}-number`}
            onChange={handleChangeInput}
            onBlur={handleBlur}
            name={name}
            value={phone}
            disabled={disabled}
            placeholder={getExamplePhoneNumber(phoneCountryCode || 'US')?.nationalNumber}
            required={required}
          >
            {label}
          </S.MainInput>
        </S.Field>
        {error && (
          <InputInvalid>
            <span>{error}</span>
            <span>
              <IconExclamationmarkCircleFill />
            </span>
          </InputInvalid>
        )}
      </S.Wrapper>
    </div>
  );
};
