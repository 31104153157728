import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';

export const WrapperOverride = styled('div', { shouldForwardProp: isPropValid })<{
  isDisabled?: boolean;
  isFullWidth?: boolean;
}>(
  ({ isDisabled = false, theme, isFullWidth = true }) => css`
    ${isFullWidth &&
    css`
      flex: 1;
    `}

    > label > span {
      font-family: ${theme.customization.fonts.body};
      font-weight: ${theme.fontWeights.m};

      & sup {
        color: ${theme.customization.colors.text.default} !important;
        font-size: 1.5rem;
        font-weight: 600;
        top: 0.3rem;
      }

      ${isDisabled &&
      css`
        font-weight: 300;
        & sup {
          font-weight: 300;
        }
      `}
    }
  `,
);

export const StyledSelectOptionWrapper = styled('div', { shouldForwardProp: isPropValid })<{
  isFocused: boolean;
  isSelected: boolean;
}>(
  ({ isFocused, isSelected, theme }) => css`
    font-size: ${theme.fontSizes.xs};

    ${isSelected &&
    css`
      color: ${theme.customization.colors.text.onCta.secondary.focused};
      background-color: ${theme.customization.colors.cta.secondary.focused};
    `}

    ${isFocused &&
    css`
      color: ${theme.customization.colors.text.onCta.secondary.hover};
      background-color: ${theme.customization.colors.cta.secondary.hover};
    `}

    &:hover {
      color: ${theme.customization.colors.text.onCta.secondary.hover};
      background-color: ${theme.customization.colors.cta.secondary.hover};
    }

    &:active {
      color: ${theme.customization.colors.text.onCta.secondary.pressed};
      background-color: ${theme.customization.colors.cta.secondary.pressed};
    }

    > div {
      background: initial !important;
      color: inherit !important;
      font-weight: lighter !important;
    }
  `,
);
