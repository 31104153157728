import styled from '@emotion/styled';

export const StyledButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.space.m} 0;
  background: ${({ theme }) => theme.customization.colors.background}CD; // #CD is 80% opacity.
  backdrop-filter: blur(${({ theme }) => theme.space.xxs});
`;
