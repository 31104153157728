import styled from '@emotion/styled';
import ChiliInput from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/Input';
import Asterisk from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/RequiredAsterisk';
import { ComponentProps } from 'react';

export const ContainerOverride = styled.div`
  & label {
    grid-template-rows: auto; // Avoid div resize issues.
  }
`;

export const Input = styled((props: ComponentProps<typeof ChiliInput>) => (
  <ChiliInput hasFloatLabel={false} {...props} />
))`
  ${({ theme }) => `
  background-color: ${theme.customization.colors.background};
  color: ${theme.customization.colors.text.default};
  font-family: ${theme.customization.fonts.body};
  font-weight: ${theme.fontWeights.m};
  transition: border-color ${theme.duration.m};

    & ~ .inputLabel {
      color: ${theme.customization.colors.text.default};
      font-size: ${theme.fontSizes.s};
    }

    & ~ .inputLabel,
    & ~ .inputLabel sup {
      font-family: ${theme.customization.fonts.body};
      color: ${theme.customization.colors.text.default} !important;
    }

    & ~ span > span {
      color: ${theme.customization.colors.text.default};
    }

    & ~ .inputBorder {
      border-color: ${theme.customization.colors.fields.border} !important;
      transition: border-color ${theme.duration.m};
    }

    &:not(:disabled):not(:focus):hover ~ .inputBorder {
      border-color: ${theme.customization.colors.fields.hover} !important;
      transition: border-color 0s;
    }

    &:disabled ~ .inputBorder {
      border-color: ${theme.customization.colors.fields.disabled} !important;
    }

    &:focus ~ .inputBorder {
      border-color: ${theme.customization.colors.fields.pressed} !important;
      transition: border-color 0s;
    }

    &:focus {
      background-color: ${theme.customization.colors.background} !important;
    }

    &:disabled {
      cursor: not-allowed;
      & ~ .inputLabel sup {
        font-weight: 300 !important;
      }
    }

    &:focus ~ .inputBorder {
      border-color: ${theme.customization.colors.fields.pressed} !important;
    }

    &::placeholder {
      color: ${theme.customization.colors.grey600} !important;
      font-weight: 300;
    }
  `}
`;

export const RequiredAsterisk = styled(Asterisk)`
  color: ${({ theme }) => theme.customization.colors.text.default};
`;
