import { FOR_DESKTOP_ONLY } from '@/utils/emotion-utils';
import { IconCalendar, IconClock, IconPerson } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { px2rem } from '@lafourchette/design-system-theming/utils';
import { Button } from '@/components/Button/Button';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import styled from '@emotion/styled';

const FILTER_BUTTON_HEIGHT = 36;

export const DhpButtonsContainer = styled.div`
  display: flex;
  border: 2px solid ${({ theme }) => theme.customization.colors.border.line};
  border-radius: ${({ theme }) => theme.radii.m};
  overflow: hidden;
  min-height: ${px2rem(FILTER_BUTTON_HEIGHT)};
  width: 100%;
  min-width: 150px;
  justify-content: space-between;
`;

export const VerticalSeparator = styled.div`
  background-color: ${({ theme }) => theme.customization.colors.border.line};
  width: ${px2rem(1)};
  height: ${px2rem(FILTER_BUTTON_HEIGHT / 2)};
  align-self: center;
`;

export const FlexC = styled(Flex)`
  z-index: 1;
  flex-direction: column;
  width: 100%;
  max-width: ${px2rem(480)};
  padding-top: ${({ theme }) => theme.space.m};
  padding-bottom: ${({ theme }) => theme.space.m};
`;

export const SearchButtonContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.space.s};
  ${FOR_DESKTOP_ONLY} {
    justify-self: start;
  }
`;

export const SearchButton = styled(Button)`
  width: 100%;
`;

export const SearchButtonAndVoucher = styled.div`
  width: 100%;
`;

export { IconCalendar, IconClock, IconPerson };
